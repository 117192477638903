import React, { useCallback } from "react";

import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { makeStyles, Snackbar, useMediaQuery } from "@material-ui/core";
import { useAlertContext } from "../contexts/Alert";
import { ReactComponent as EmailWarningIcon } from "../assets/icons/email-warning.svg";
import CustomIcon from "./CustomIcon";
import clsx from "clsx";

const AlertWrapper = () => {
  const classes = useStyles();
  const [config, dispatch] = useAlertContext();
  const handleOnClose = useCallback(
    (_, reason) => {
      if (reason === "clickaway") return;
      dispatch({ type: "close" });
    },
    [dispatch]
  );
  const desktop = useMediaQuery("(min-width:540px)");
  return (
    <Snackbar
      anchorOrigin={desktop ? config.anchorOrigin : { vertical: "bottom", horizontal: "center" }}
      open={config.open}
      autoHideDuration={config.autoHideDuration}
      onClose={handleOnClose}
    >
      <Alert
        iconMapping={{
          info: <CustomIcon fontSize="large" name="CheckCircle" />,
          error: <CustomIcon fontSize="large" name="XCircle" />,
          success: <CustomIcon fontSize="large" name="CheckCircle" />,
          warning: config.email ? (
            <EmailWarningIcon fontSize="large" />
          ) : (
            <CustomIcon fontSize="large" name="AlertTriangle" />
          ),
        }}
        onClose={handleOnClose}
        severity={config.severity}
        classes={{
          root: classes.root,
          message: clsx([
            classes.message,
            classes.over,
            { [classes.bold]: !config.title },
          ]),
          icon: clsx([
            classes.over,
            classes.icon,
            { [classes[config.severity]]: true },
          ]),
          action: classes.over,
        }}
      >
        {config.title && (
          <AlertTitle className={classes.title}>{config.title}</AlertTitle>
        )}
        {config.text}
      </Alert>
    </Snackbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.07)",
    padding: 0,
    backgroundColor: theme.palette.common.white,
    overflow: "hidden",
    width: 420,
    minHeight: 70,
    fontWeight: "normal",
    verticalAlign: "middle",
  },
  title: {
    fontWeight: 700,
  },
  message: {
    alignSelf: "center",
  },
  bold: {
    fontWeight: 700,
  },
  icon: {
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      height: "100%",
      width: "30%",
      zIndex: 2,
    },
    "& > svg": {
      alignSelf: "center",
      zIndex: 10,
    },
  },
  info: {
    backgroundColor: theme.palette.info.light,
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  error: {
    backgroundColor: theme.palette.error.light,
  },
  over: {
    zIndex: 10,
    padding: theme.spacing(1.5, 2),
    "&:first-child": {
      paddingRight: 0,
    },
    "&:nth-child(2)": {
      paddingLeft: 0,
    },
  },
}));

export default AlertWrapper;
