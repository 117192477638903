import React, { useState, useEffect, useContext } from "react";
import Loading from "../pages/Loading";
import { useFirebaseContext } from "./Firebase";

const DEFAULT_NUMBER_OF_DOCS = 0;

export const ConfigContext = React.createContext(null);
export const useConfigContext = () => useContext(ConfigContext)

const ConfigProvider = ({ children }) => {
  const firebase = useFirebaseContext();
  const [config, setConfig] = useState({
    colors: [],
    sizes: [],
    categories: [],
    types: [],
    extras: [],
    counters: {
      items: { numberOfDocs: DEFAULT_NUMBER_OF_DOCS },
      orders: { numberOfDocs: DEFAULT_NUMBER_OF_DOCS },
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { docs: colorDocs = [] } = await firebase
        .colors()
        .orderBy("name")
        .get();
      const { docs: sizeDocs = [] } = await firebase
        .sizes()
        .orderBy("name")
        .get();
      const { docs: categoriesDocs = [] } = await firebase
        .categories()
        .orderBy("name")
        .get();
      const { docs: typesDocs = [] } = await firebase
        .types()
        .orderBy("order")
        .get();
      const { docs: extrasDocs = [] } = await firebase
        .extras()
        .get();
      const itemsCounterDoc = await firebase.counter("items").get();
      const ordersCounterDoc = await firebase.counter("orders").get();
      const colors = colorDocs.map((c) => c.data());
      const sizes = sizeDocs.map((s) => s.data());
      const categories = categoriesDocs.map((c) => c.data());
      const extras = extrasDocs.map((c) => c.data());
      const types = typesDocs.map((c) => c.data());
      setConfig({
        colors,
        sizes,
        categories,
        types,
        extras,
        counters: {
          items: itemsCounterDoc
            ? itemsCounterDoc.data()
            : { numberOfDocs: DEFAULT_NUMBER_OF_DOCS },
          orders: ordersCounterDoc
            ? ordersCounterDoc.data()
            : { numberOfDocs: DEFAULT_NUMBER_OF_DOCS },
        },
      });
      setLoading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export default ConfigProvider;
