import React from "react";
import ReactMarkdown from "react-markdown";
import terms from "../config/terms";

const TermsAndConditions = () => {
  return (
    <div className="terms">
      <ReactMarkdown source={terms} />
    </div>
  );
};

export default TermsAndConditions;
