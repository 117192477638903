import { makeStyles, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const RadioCard = ({ icon, onClick, selected, name }) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={1}
      className={clsx([classes.root, selected ? classes.selected : ""])}
      onClick={onClick}
    >
      {icon}
      <Typography variant="body1">{name}</Typography>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    borderRadius: 20,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    borderWidth: 1,
    cursor: "pointer",
    minWidth: 120,
    maxWidth: 120,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(8),
      minWidth: 200,
      maxWidth: 200,
    },
  },
  selected: {
    borderColor: theme.palette.grey[700],
  },
}));

export default RadioCard;
