// ** Algolia Search API **
import algoliasearch from "algoliasearch";

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID || "",
  process.env.REACT_APP_ALGOLIA_API_KEY || ""
);

export const getIndex = (indexName) => client.initIndex(indexName);

export const searchInIndex = async (query, params = {}, indexName) => {
  const index = client.initIndex(indexName);
  // with params
  return index.search(query, { ...params });
};
