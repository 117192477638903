import React, { useState, useEffect, useContext } from "react";
import { useFirebaseContext } from "./Firebase";
import Loading from "../pages/Loading";

export const UserContext = React.createContext(null);
export const useUserContext = () => useContext(UserContext)

const UserProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const firebase = useFirebaseContext();

  useEffect(() => {
    let unsubscribe = null;
    firebase.auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        unsubscribe = firebase.user(authUser.uid).onSnapshot((doc) => {
          let userData = null;
          if (doc.exists) {
            userData = doc.data();
          }
          setData(userData);
          setLoading(false);
        });
        setProfile(authUser);
      } else {
        setProfile(null);
        setData(null);
        setLoading(false);
        if (unsubscribe) {
          unsubscribe();
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <UserContext.Provider value={{ profile, data }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
