import React, { useCallback, useState, useEffect } from "react";
import { makeStyles, Typography, Paper } from "@material-ui/core";
import { useFirebaseContext } from "../contexts/Firebase";
import { useUserContext } from "../contexts/User";
import { useAlertContext } from "../contexts/Alert";
import UserForm from "../components/UserForm";
import BaseButton from "../components/BaseButton";
import { Link, useLocation } from "react-router-dom";
import useAddresses from "../hooks/addresses.hook";
import ROUTE_NAMES from "../config/routes";

const Profile = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const firebase = useFirebaseContext();
  const [, alertDispatch] = useAlertContext();
  const { profile, data: userData = {} } = useUserContext();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    debounceFetchLocations: fetchLocations,
    loading: loadingLocs,
    locations,
  } = useAddresses(firebase);

  const handleResendValidationEmail = useCallback(async () => {
    try {
      await firebase.resendValidationEmail();
      alertDispatch({
        type: "close",
      });
      await new Promise((resolve) => setTimeout(resolve, 100));
      alertDispatch({
        type: "show",
        payload: {
          severity: "info",
          autoHideDuration: null,
          email: false,
          title: null,
          text: `A validation email has been sent to ${profile.email}.`,
        },
      });
    } catch (error) {}
  }, [firebase, alertDispatch, profile]);

  useEffect(() => {
    if (state && state.alert) {
      if (state.alert === "email-verification") {
        alertDispatch({
          type: "show",
          payload: {
            title: "Check email",
            autoHideDuration: null,
            text: (
              <Typography variant="body1">
                Please check your email inbox and click on the provided link. If
                you don’t receive email,{" "}
                <span className="c-link" onClick={handleResendValidationEmail}>
                  click here
                </span>{" "}
                to resend. Please check junk mail.
              </Typography>
            ),
            severity: "warning",
            email: true,
          },
        });
      } else if (state.alert === "complete-profile") {
        alertDispatch({
          type: "show",
          payload: {
            title: null,
            text: "Complete your profile",
            severity: "warning",
            email: false,
            autoHideDuration: 3000,
          },
        });
      }
    }
  }, [state, alertDispatch, handleResendValidationEmail]);

  const handleSubmit = useCallback(async () => {
    const { isValid, values } = form;
    if (!isValid) return;
    setLoading(true);
    const { updatedAt } = firebase.getFirebaseTimestamps();
    await firebase
      .user(profile.uid)
      .update({ ...values, validated: true, updatedAt });
    alertDispatch({
      type: "show",
      payload: {
        text: (
          <Typography>
            Profile updated.{" "}
            <Link className={classes.link} to={ROUTE_NAMES.CREATE_ORDER}>
              Start ordering now.
            </Link>
          </Typography>
        ),
        severity: "success",
      },
    });
    setLoading(false);
  }, [firebase, form, profile, alertDispatch, classes]);

  const handleUpdateInformation = useCallback((formData) => {
    setForm(formData);
  }, []);

  useEffect(() => {
    if (userData && !userData.postcodeID) {
      alertDispatch({
        type: "show",
        payload: {
          severity: "warning",
          text: "Please update your current address",
        },
      });
    }
  }, [userData, alertDispatch]);

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Typography gutterBottom color="primary" variant="subtitle2">
          User
        </Typography>
        <div className={classes.formContainer}>
          <UserForm
            initialValues={userData || {}}
            handleUpdateData={handleUpdateInformation}
            loadingLocations={loadingLocs}
            onSearchLocation={fetchLocations}
            locations={locations}
          >
            <div className={classes.submitContainer}>
              <BaseButton
                disabled={!form.isValid || loading}
                className={classes.submit}
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                loading={loading}
              >
                Save
              </BaseButton>
            </div>
          </UserForm>
        </div>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 6),
    },
  },
  paper: {
    borderRadius: 20,
    height: "100%",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 6),
    },
  },
  submitContainer: {
    margin: theme.spacing(2, 0),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    },
  },
  submit: {
    minWidth: 150,
  },
  formContainer: {
    margin: theme.spacing(2, 0),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

export default Profile;
