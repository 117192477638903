import React, { useState, useCallback, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  IconButton,
  Hidden,
  Popover,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Checkbox,
  ListItemIcon,
  withStyles,
  createStyles,
} from "@material-ui/core";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import ColorSquare from "./ColorSquare";
import currencyFormatter from "../utils/currency";
import { ReactComponent as BootIcon } from "../assets/icons/boot.svg";
import CustomIcon from "./CustomIcon";
import PropTypes from "prop-types";

const styles = createStyles((theme) => ({
  title: {
    padding: theme.spacing(0, 3),
    paddingTop: theme.spacing(2),
  },
  list: {
    padding: theme.spacing(0, 3),
    paddingBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: 20,
  },
}));

const ExtrasPopover = withStyles(styles)(
  ({ handleClose, items, classes, anchorEl }) => {
    return (
      <Popover
        classes={{ paper: classes.paper }}
        anchorEl={anchorEl}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.title} variant="body2">
          Sizes with extra cost
        </Typography>
        <List>
          {items.map((i) => (
            <ListItem className={classes.list} key={i.name}>
              <Typography
                className={classes.grow}
                component="span"
                variant="body1"
              >
                {i.name}
              </Typography>
              <Typography color="primary" component="span" variant="body1">
                {currencyFormatter.format(i.value)}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Popover>
    );
  }
);

const MIN_QUANTITY_VALUE = 1;
const MAX_QUANTITY_VALUE = 9999;
const DISABLED_SELECT_VALUE = "none";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const hasAnyExtra = (sizes = [], selected = []) => {
  return sizes.filter((s) => s.extra).filter((s) => selected.includes(s.id));
};

const ShoppingItemControl = ({
  images = [],
  colors,
  sizes,
  name,
  price,
  discount,
  discountQuantity,
  id,
  isFavorite,
  onAddItem,
  onToggleFavorite,
  loading,
}) => {
  const classes = useStyles();
  const dImage = images.find((i) => i.default);
  const defaultImage = dImage ? dImage.src : <BootIcon />;
  const [image, setImage] = useState(defaultImage);
  const [color, setColor] = useState(DISABLED_SELECT_VALUE);
  const [size, setSize] = useState([DISABLED_SELECT_VALUE]);
  const [quantity, setQuantity] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [extrasAnchorEl, setExtrasAnchorEl] = useState(null);
  const desktop = useMediaQuery("(min-width:960px)");

  const handleOpenExtras = useCallback((e) => {
    setExtrasAnchorEl(e.currentTarget);
  }, []);

  const handleCloseExtras = useCallback(() => {
    setExtrasAnchorEl(null);
  }, []);

  const handleOpenPopover = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleColorChange = useCallback((e) => {
    setColor(e.target.value);
  }, []);

  const handleSizeChange = useCallback((e) => {
    const val = e.target.value.filter((id) => id !== DISABLED_SELECT_VALUE);
    if (val.length === 0) {
      setSize([DISABLED_SELECT_VALUE]);
    } else {
      setSize(val);
    }
  }, []);

  const handleQuantityChangee = useCallback((e) => {
    const val = Number(e.target.value);
    if (!val) {
      setQuantity("");
    }
    if (val >= MIN_QUANTITY_VALUE && val <= MAX_QUANTITY_VALUE) {
      setQuantity(val);
    }
  }, []);

  const handleOnAddItem = useCallback(() => {
    if (!onAddItem) return;
    onAddItem(id, quantity, color, size);
    setQuantity("");
    setColor(DISABLED_SELECT_VALUE);
    setSize([DISABLED_SELECT_VALUE]);
  }, [onAddItem, id, quantity, color, size]);

  const getDiscount = useCallback(() => {
    if (!discount || !discountQuantity) return 0;
    if (quantity >= discountQuantity) {
      return price - price * discount;
    }
  }, [quantity, price, discount, discountQuantity]);

  const colorSelector = (
    <>
      <Typography variant="body1">Colour:</Typography>
      <TextField
        select
        value={color}
        onChange={handleColorChange}
        placeholder="Select"
        variant="outlined"
        size="small"
        fullWidth
        inputProps={{ className: classes.capitalize }}
        SelectProps={{
          IconComponent: color ? () => <></> : undefined,
          classes: { outlined: classes.colorSelect },
        }}
      >
        <MenuItem
          disabled
          key={DISABLED_SELECT_VALUE}
          value={DISABLED_SELECT_VALUE}
        >
          <Typography variant="inherit">Select</Typography>
        </MenuItem>
        {colors.map((option) => (
          <MenuItem
            className={classes.capitalize}
            key={option.id}
            value={option.id}
          >
            <Typography className={classes.colorLabel} variant="inherit">
              {option.name}
            </Typography>
            <ListItemIcon className={classes.colorSquare}>
              <ColorSquare color={option.hex} />
            </ListItemIcon>
          </MenuItem>
        ))}
      </TextField>
    </>
  );

  const sizeSelector = (
    <>
      <Typography variant="body1">Size:</Typography>
      <TextField
        select
        value={size}
        onChange={handleSizeChange}
        placeholder="Select"
        variant="outlined"
        size="small"
        fullWidth
        SelectProps={{
          multiple: true,
          renderValue: (selected) =>
            selected
              .map((s) => {
                if (s === DISABLED_SELECT_VALUE) return "Select";
                return sizes.find((i) => i.id === s).name;
              })
              .join(", "),
          MenuProps: MenuProps,
        }}
        inputProps={{ className: classes.capitalize }}
      >
        <MenuItem
          disabled
          key={DISABLED_SELECT_VALUE}
          value={DISABLED_SELECT_VALUE}
        >
          <Typography variant="inherit">Select</Typography>
        </MenuItem>
        {sizes
          .sort((a, b) => a.order - b.order)
          .map((option) => (
            <MenuItem
              className={classes.capitalize}
              key={option.id}
              value={option.id}
            >
              <Checkbox checked={size.indexOf(option.id) > -1} />
              <ListItemText
                primary={option.name}
                secondary={
                  option.extra ? (
                    <Typography color="primary">{`+ ${currencyFormatter.format(
                      option.extra
                    )}`}</Typography>
                  ) : null
                }
              />
            </MenuItem>
          ))}
      </TextField>
    </>
  );

  const handleToggleFavorite = useCallback(() => {
    if (onToggleFavorite) {
      onToggleFavorite(id);
      handleClosePopover();
    }
  }, [onToggleFavorite, id, handleClosePopover]);

  useEffect(() => {
    if (!Boolean(color) || color === DISABLED_SELECT_VALUE) {
      setImage(defaultImage);
    } else {
      const colorData = colors.find((c) => c.id === color);
      if (colorData) {
        const img = images.find((i) => i.color === colorData.code);
        setImage(img ? img.src : defaultImage);
      } else {
        setImage(defaultImage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, colors, images]);

  const calculatedDiscount = getDiscount();
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "options-popover" : undefined;
  const actionDisabled =
    !Boolean(color) ||
    color === DISABLED_SELECT_VALUE ||
    isEmpty(size) ||
    size[0] === DISABLED_SELECT_VALUE ||
    !Boolean(quantity);

  const moreOptionsButton = (
    <>
      <IconButton
        color="default"
        aria-describedby={popoverId}
        onClick={handleOpenPopover}
        className={classes.iconBgButton}
      >
        <CustomIcon name="MoreVertical" />
      </IconButton>
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: desktop ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: desktop ? "left" : "right",
        }}
      >
        <List component="nav" aria-label="item options">
          {/* <ListItem button>
            <ListItemText primary="Add advanced" />
          </ListItem> */}
          <ListItem button onClick={handleToggleFavorite} disabled={loading}>
            <ListItemText
              primary={isFavorite ? "Remove favourite" : "Add favourite"}
            />
          </ListItem>
        </List>
      </Popover>
    </>
  );

  const selectedExtras = hasAnyExtra(sizes, size);

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <ExtrasPopover
        anchorEl={extrasAnchorEl}
        handleClose={handleCloseExtras}
        items={selectedExtras.map((e) => ({ name: e.code, value: e.extra }))}
      />
      <Grid className={classes.imageContainer} xs={10} sm={11} md={2} item>
        {typeof image === "string" ? (
          <img src={image} alt={name} className={classes.image} />
        ) : (
          image
        )}
      </Grid>
      <Grid xs={10} sm={11} md={9} item className={classes.grow}>
        <div className={classes.name}>
          <Typography
            align="left"
            className={classes.capitalize}
            color="primary"
            variant="subtitle2"
          >
            {isFavorite && (
              <CustomIcon
                name="Star"
                htmlColor="#ffc107"
                fill="#ffc107"
                className={classes.star}
                onClick={handleToggleFavorite}
              />
            )}
            {name}
          </Typography>
          <Hidden mdUp>{moreOptionsButton}</Hidden>
        </div>
        <Grid
          container
          alignItems="center"
          className={classes.controls}
          spacing={2}
        >
          <Hidden smDown>
            <Grid
              xs={false}
              sm={false}
              md={1}
              item
              className={classes.marginButton}
            >
              {moreOptionsButton}
            </Grid>
          </Hidden>
          <Grid xs={12} sm={12} md={3} item>
            {colorSelector}
          </Grid>
          <Grid xs={12} sm={12} md={3} item>
            {sizeSelector}
          </Grid>
          <Grid xs={12} sm={12} md={2} item>
            <Typography variant="body1">Quantity:</Typography>
            <TextField
              variant="outlined"
              type="number"
              placeholder="-"
              value={quantity}
              onChange={handleQuantityChangee}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={12} md={3} item>
            <Typography variant="body1">Unit price:</Typography>
            <div className={classes.prices}>
              <Typography
                className={clsx([
                  { [classes.crossed]: Boolean(calculatedDiscount) },
                ])}
                variant="body1"
              >
                {currencyFormatter.format(price)}
              </Typography>
              {selectedExtras.length > 0 && (
                <Typography
                  className={classes.extra}
                  variant="caption"
                  onClick={handleOpenExtras}
                >
                  Extra applied
                  <CustomIcon
                    className={classes.extraIcon}
                    fontSize="small"
                    name="Info"
                  />
                </Typography>
              )}
              {Boolean(calculatedDiscount) && (
                <Typography className={classes.discount} variant="body1">
                  {currencyFormatter.format(calculatedDiscount)}
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={10} sm={11} md={1} item>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          onClick={handleOnAddItem}
          fullWidth
          disabled={actionDisabled || loading}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    height: "100%",
    marginTop: "unset",
    padding: theme.spacing(2, 1),
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(2),
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },
  grow: {
    flex: 1,
  },
  button: {
    padding: theme.spacing(0, 2),
  },
  colorSelect: {
    display: "flex",
    alignItems: "center",
  },
  colorLabel: {
    flexGrow: 1,
  },
  colorSquare: {
    display: "block",
    textAlign: "right",
    "& > *": {
      verticalAlign: "middle",
    },
  },
  name: {
    alignItems: "center",
    display: "flex",
    "& > h6": {
      flex: 1,
    },
  },
  controls: {
    justifyContent: "center",
    margin: theme.spacing(2, 0),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      margin: "unset",
      justifyContent: "flex-start",
      width: "unset",
    },
  },
  prices: {
    // alignItems: "center",
    // display: "flex",
    // justifyContent: "flex-start",
    // marginBottom: 1,
  },
  discount: {
    margin: theme.spacing(0, 2),
  },
  crossed: {
    opacity: 0.4,
    textDecoration: "line-through",
  },
  imageContainer: {
    textAlign: "center",
  },
  image: {
    height: "auto",
    width: "100%",
    maxWidth: 300,
    [theme.breakpoints.up("md")]: {
      width: 120,
    },
  },
  star: {
    cursor: "pointer",
    marginBottom: 3,
    marginRight: 4,
    verticalAlign: "middle",
  },
  iconBgButton: {
    backgroundColor: "whitesmoke",
    "&:hover": {
      backgroundColor: theme.palette.divider,
    },
  },
  marginButton: {
    margin: theme.spacing(0, 1, 0, -1),
  },
  extra: {
    color: theme.palette.error.main,
    cursor: "pointer",
  },
  extraIcon: {
    marginLeft: theme.spacing(0.5),
    verticalAlign: "middle",
  },
}));

ShoppingItemControl.propTypes = {
  images: PropTypes.array,
  colors: PropTypes.array,
  sizes: PropTypes.array,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  discount: PropTypes.number,
  discountQuantity: PropTypes.number,
  id: PropTypes.string.isRequired,
  isFavorite: PropTypes.bool,
  onAddItem: PropTypes.func,
  onToggleFavorite: PropTypes.func,
  loading: PropTypes.bool,
};

export default ShoppingItemControl;
