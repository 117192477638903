import React from "react";
import PropTypes from "prop-types";

const ColorSquare = ({
  className,
  color: fill = "#fff",
  stroke = "#fff",
  size = 15,
}) => {
  return (
    <svg className={className} width={size} height={size}>
      <rect
        width={size}
        height={size}
        style={{
          fill,
          strokeWidth: 1,
          stroke,
        }}
      />
    </svg>
  );
};

ColorSquare.prototype = {
  className: PropTypes.string,
  color: PropTypes.string,
  stroke: PropTypes.string,
  size: PropTypes.number,
};

export default ColorSquare;
