import React, { useState, useCallback } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Button,
  Hidden,
  Fab,
} from "@material-ui/core";
import { useFirebaseContext } from "../contexts/Firebase";
import { useAlertContext } from "../contexts/Alert";
import { useUserContext } from "../contexts/User";
import CreditCard from "../components/CreditCard";
import ModalFormWrapper from "../components/ModalFormWrapper";
import CardForm from "../components/CardForm";
import BaseButton from "../components/BaseButton";
import CustomIcon from "../components/CustomIcon";
import { ReactComponent as EmptyCardsIcon } from "../assets/icons/card-empty.svg";
import PremiumAccountCard from "../components/PremiumAccountCard";

const PaymentMethods = ({ handleSetLayoutLoader }) => {
  const classes = useStyles();
  const firebase = useFirebaseContext();
  const [, alertDispatch] = useAlertContext();
  const { data: userData } = useUserContext();
  const [form, setForm] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { uid, cards = [] } = userData;

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleUpdateInformation = useCallback((formData) => {
    setForm(formData);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!uid) return;
    const { isValid, values } = form;
    if (!isValid) return;
    handleSetLayoutLoader(true);
    setLoading(true);
    const exists = cards.find((c) => c.number === values.number);
    if (Boolean(exists)) {
      alertDispatch({
        type: "show",
        payload: { text: "Credit card already added", severity: "error" },
      });
    } else {
      await firebase.user(uid).update({
        cards: firebase.getFirestoreArrayUtils().union(values),
      });
      alertDispatch({ type: "show", payload: { text: "Credit card added" } });
    }
    setOpenModal(false);
    handleSetLayoutLoader(false);
    setLoading(false);
  }, [firebase, uid, handleSetLayoutLoader, form, alertDispatch, cards]);

  const handleDeleteCard = useCallback(
    async (card) => {
      if (!uid) return;
      handleSetLayoutLoader(true);
      setLoading(true);
      await firebase.user(uid).update({
        cards: firebase.getFirestoreArrayUtils().remove(card),
      });
      alertDispatch({ type: "show", payload: { text: "Credit card removed" } });
      handleSetLayoutLoader(false);
      setLoading(false);
    },
    [firebase, handleSetLayoutLoader, uid, alertDispatch]
  );

  const emptyState = (
    <div className={classes.empty}>
      <EmptyCardsIcon />
      <div>
        <Typography variant="subtitle1">
          You have not added credit cards yet
        </Typography>
        <Typography variant="body1">Start adding credit cards</Typography>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <ModalFormWrapper
        title="Add credit card"
        open={openModal}
        handleClose={handleCloseModal}
      >
        <CardForm showCardTypeImage handleUpdateData={handleUpdateInformation}>
          <div className={classes.submitContainer}>
            <BaseButton
              loading={loading}
              disabled={!form.isValid || loading}
              className={classes.submit}
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleSubmit}
            >
              Save
            </BaseButton>
          </div>
        </CardForm>
      </ModalFormWrapper>
      {userData && userData.premium && <PremiumAccountCard badge />}
      <Paper elevation={0} className={classes.paper}>
        <Hidden xsDown>
          <Button
            className={classes.add}
            color="primary"
            variant="contained"
            aria-label="add new card"
            onClick={handleOpenModal}
            disabled={loading}
          >
            Add new card
          </Button>
        </Hidden>
        <Hidden smUp>
          <Fab
            className={classes.add}
            color="primary"
            aria-label="add new card"
            onClick={handleOpenModal}
            disabled={loading}
            size="small"
          >
            <CustomIcon fontSize="small" name="Plus" />
          </Fab>
        </Hidden>
        <Typography
          className={classes.title}
          color="primary"
          variant="subtitle2"
        >
          My Cards
        </Typography>
        <div className={classes.cards}>
          {cards && cards.length === 0 && !loading && emptyState}
          {cards &&
            cards.map((card, i) => (
              <CreditCard
                key={card.number}
                classes={{ root: classes.card }}
                type={card.type}
                number={card.number}
                exp={card.exp}
                onDelete={handleDeleteCard.bind(null, card)}
                showDeleteButton
              />
            ))}
        </div>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 6),
    },
    "& > *": {
      margin: theme.spacing(2, 0),
    },
  },
  paper: {
    borderRadius: 20,
    position: "relative",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
  cards: {
    alignItems: "stretch",
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },
  card: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      flexBasis: 230,
      margin: theme.spacing(1, 1, 1, 0),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(1, 2, 1, 0),
    },
  },
  submitContainer: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  submit: {
    minWidth: 150,
  },
  emoji: { fontSize: "2em", marginLeft: 6 },
  empty: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    width: "100%",
  },
  add: {
    position: "absolute",
    right: 10,
    top: 10,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 3),
      right: 30,
      top: 25,
    },
  },
}));

export default PaymentMethods;
