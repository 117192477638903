import React from "react";
import { useUserContext } from "../contexts/User";
import { Route, Redirect } from "react-router-dom";
import ROUTE_NAMES from "../config/routes";
import PropTypes from "prop-types";

/**
 *
 * Custom route guard to prevent logged users access
 */
const NonAuthRoute = ({ component, ...rest }) => {
  const { profile, data } = useUserContext();
  if (!profile || !data) {
    return <Route {...rest} component={component} />;
  }
  if (rest.location && rest.location.state && rest.location.state.from) {
    return <Redirect to={rest.location.state.from} />;
  } else {
    return <Redirect to={ROUTE_NAMES.CREATE_ORDER} />;
  }
};

NonAuthRoute.propTypes = {
  children: PropTypes.element,
};

export default NonAuthRoute;
