import React, { createContext, useContext, useReducer } from "react";

const SNACKBAR_ANCHOR_ORIGIN = { vertical: "top", horizontal: "center" };
const SNACKBAR_AUTO_HIDE_DURATION = 5000; // ms
const ALERT_ELEVATION = 6;
const ALERT_SEVERITY = "success";
const ALERT_VARIANT = "filled";

export const AlertContext = createContext(null);
export const useAlertContext = () => useContext(AlertContext)

const initialState = {
  anchorOrigin: SNACKBAR_ANCHOR_ORIGIN,
  autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION,
  elevation: ALERT_ELEVATION,
  email: false,
  onClose: null,
  open: false,
  severity: ALERT_SEVERITY,
  text: null,
  title: null,
  variant: ALERT_VARIANT,
};

function reducer(state, action) {
  switch (action.type) {
    case "close":
      return {
        ...state,
        open: false,
      };
    case "show":
      return { ...initialState, open: true, ...action.payload };
    default:
      return state;
  }
}

const AlertProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AlertContext.Provider value={[state, dispatch]}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
