import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./config/theme";
import Routes from "./routes/Routes";
import FirebaseProvider from "./contexts/Firebase";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import UserProvider from "./contexts/User";
import ConfigProvider from "./contexts/Config";
import AlertProvider from "./contexts/Alert";
import AlertWrapper from "./components/AlertWrapper";

function App() {
  return (
    <FirebaseProvider>
      <UserProvider>
        <ConfigProvider>
          <AlertProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router>
                <Routes />
                <AlertWrapper />
              </Router>
            </ThemeProvider>
          </AlertProvider>
        </ConfigProvider>
      </UserProvider>
    </FirebaseProvider>
  );
}

export default App;
