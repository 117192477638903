import React, { useCallback, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";

const parseAddress = (values = {}) => {
  if (values.city && values.state && values.zip && values.postcodeID) {
    return `${values.zip} - ${values.city} - ${values.state}`;
  } else {
    return "";
  }
};

const AutocompleteAddress = ({
  locations = [],
  onSearchLocation,
  loadingLocations = false,
  values = {},
  onLocationSelected,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(parseAddress(values));

  const handleToggleLocationOpen = useCallback(
    (isOpen) => {
      setOpen(isOpen);
      onSearchLocation && isOpen && onSearchLocation();
    },
    [onSearchLocation]
  );

  const handleOnAutocompleteChange = useCallback(
    (e) => {
      setInputValue(e.target.value);
      onSearchLocation && onSearchLocation(e.target.value);
    },
    [onSearchLocation]
  );

  const handleLocationSelected = useCallback(
    (e, val) => {
      if (val) {
        setInputValue(`${val.postcode} - ${val.city} - ${val.state}`);
        onLocationSelected &&
          onLocationSelected({
            city: val.city,
            zip: val.postcode,
            state: val.state,
            postcodeID: val.id,
          });
      } else {
        setInputValue("");
        onLocationSelected &&
          onLocationSelected({
            city: "",
            zip: "",
            state: "",
            postcodeID: "",
          });
      }
    },
    [onLocationSelected]
  );

  return (
    <Autocomplete
      id="locations-autocomplete"
      open={open}
      inputValue={inputValue}
      value={values.postcodeID}
      onOpen={handleToggleLocationOpen.bind(null, true)}
      onClose={handleToggleLocationOpen.bind(null, false)}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : `${option.postcode} - ${option.city} - ${option.state}`
      }
      autoComplete
      autoSelect
      filterOptions={(x) => x}
      onChange={handleLocationSelected}
      filterSelectedOptions
      options={locations}
      loading={loadingLocations}
      renderInput={(params) => {
        params.inputProps.className = `c-autocomplete-input ${params.inputProps.className}`;
        params.inputProps.autoComplete = "newPassword";
        return (
          <TextField
            {...params}
            fullWidth
            label="City/Suburb"
            variant="outlined"
            onChange={handleOnAutocompleteChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingLocations ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default AutocompleteAddress;
