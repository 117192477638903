import React, { useCallback, useEffect } from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import useForm from "../hooks/form.hook";
import * as yup from "yup";
import RadioCard from "../components/RadioCard";
import CustomIcon from "../components/CustomIcon";
import { useFirebaseContext } from "../contexts/Firebase";
import { useUserContext } from "../contexts/User";

export const PACKAGING_TYPES = {
  BAGS: "bags",
  BOXES: "boxes",
  NOTHING: "nothing",
};

export const SHIPPING_METHODS = {
  SHIPPING: "shipping",
  PICKUP: "pickup",
};

const settingsValidationSchema = yup.object({
  packagingType: yup.string().oneOf(Object.values(PACKAGING_TYPES)).required(),
  shippingMethod: yup
    .string()
    .oneOf(Object.values(SHIPPING_METHODS))
    .required(),
});

const Settings = () => {
  const classes = useStyles();
  const firebase = useFirebaseContext();
  const { data } = useUserContext();
  const { values, setValues } = useForm(
    data && data.preferences
      ? data.preferences
      : {
          packagingType: PACKAGING_TYPES.BAGS,
          shippingMethod: SHIPPING_METHODS.SHIPPING,
        },
    settingsValidationSchema
  );

  const handlePreferenceChange = useCallback(
    (pref, value) => {
      setValues((v) => ({ ...v, [pref]: value }));
    },
    [setValues]
  );

  useEffect(() => {
    if (values) {
      (async () => {
        const { updatedAt } = firebase.getFirebaseTimestamps();
        await firebase.currentUser().update({
          updatedAt,
          preferences: values,
        });
      })();
    }
  }, [values, firebase]);

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Typography gutterBottom color="primary" variant="subtitle2">
          Preferences
        </Typography>
        <Typography align="center" variant="subtitle1">
          Which type of packaging do you prefer for your orders?
        </Typography>
        <div className={classes.group}>
          <RadioCard
            name="Bags"
            selected={values.packagingType === PACKAGING_TYPES.BAGS}
            icon={<CustomIcon fontSize="large" name="ShoppingBag" />}
            onClick={handlePreferenceChange.bind(
              null,
              "packagingType",
              PACKAGING_TYPES.BAGS
            )}
          />
          <RadioCard
            name="Boxes"
            selected={values.packagingType === PACKAGING_TYPES.BOXES}
            icon={<CustomIcon fontSize="large" name="Box" />}
            onClick={handlePreferenceChange.bind(
              null,
              "packagingType",
              PACKAGING_TYPES.BOXES
            )}
          />
          <RadioCard
            name="Nothing"
            selected={values.packagingType === PACKAGING_TYPES.NOTHING}
            icon={<CustomIcon fontSize="large" name="MinusCircle" />}
            onClick={handlePreferenceChange.bind(
              null,
              "packagingType",
              PACKAGING_TYPES.NOTHING
            )}
          />
        </div>
        <Typography align="center" variant="subtitle1">
          Which is the best shipping method for you?
        </Typography>
        <div className={classes.group}>
          <RadioCard
            name="Shipping"
            selected={values.shippingMethod === SHIPPING_METHODS.SHIPPING}
            icon={<CustomIcon fontSize="large" name="Truck" />}
            onClick={handlePreferenceChange.bind(
              null,
              "shippingMethod",
              SHIPPING_METHODS.SHIPPING
            )}
          />
          <RadioCard
            name="Pickup"
            selected={values.shippingMethod === SHIPPING_METHODS.PICKUP}
            icon={<CustomIcon fontSize="large" name="MapPin" />}
            onClick={handlePreferenceChange.bind(
              null,
              "shippingMethod",
              SHIPPING_METHODS.PICKUP
            )}
          />
        </div>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 6),
    },
  },
  paper: {
    borderRadius: 20,
    height: "100%",
    padding: theme.spacing(1, 2),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 6),
    },
  },
  radio: {
    display: "none",
  },
  group: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: theme.spacing(2, 0),
    "& > *": {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

export default Settings;
