import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  debounce,
  Grid,
  Hidden,
  makeStyles,
  Modal,
  Paper,
  Slide,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { searchInIndex } from "../services/algolia";
import InfiniteScroll from "react-infinite-scroll-component";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import { ReactComponent as EmptyOrderIcon } from "../assets/icons/order-empty.svg";
import OrderSummary from "../components/OrderSummary";
import Search from "../components/Search";
import ShoppingItemControl from "../components/ShoppingItemControl";
import { useConfigContext } from "../contexts/Config";
import { useFirebaseContext } from "../contexts/Firebase";
import { useUserContext } from "../contexts/User";
import currencyFormatter from "../utils/currency";
import get from "../utils/getter";
import { useAlertContext } from "../contexts/Alert";
import ROUTE_NAMES from "../config/routes";
import CustomIcon from "../components/CustomIcon";
import useOrder from "../hooks/order.hook";

/**
 *
 * @param {Object} item item object directly from the database
 * @param {Array} colors whole colors collection
 * @param {Array} extras whole extras collection
 */
const parseAvailableItemColors = (item, colors, extras = []) => {
  if (!item.colors) return colors;
  let availableExtras = [];
  let result = colors.filter((c) => item.colors.includes(c.code));
  if (item.extras) {
    availableExtras = extras.filter((e) => item.extras.includes(e.id));
  }
  if (availableExtras.length > 0) {
    result = result.map((c) => {
      const extra = availableExtras
        .map((e) => {
          if (!e.colors) return 0;
          const colorIncluded = e.colors.find((cc) => cc.id === c.id);
          if (colorIncluded) return e.value;
          return 0;
        })
        .reduce((t, n) => t + n, 0);
      if (extra) return { ...c, extra };
      return c;
    });
  }
  return result;
};
const parseAvailableItemSizes = (item, sizes, extras = []) => {
  if (!item.sizes) return sizes;
  let availableExtras = [];
  let result = sizes.filter((s) => item.sizes.includes(s.code));
  if (item.extras) {
    availableExtras = extras.filter((e) => item.extras.includes(e.id));
  }
  if (availableExtras.length > 0) {
    result = result.map((s) => {
      const extra = availableExtras
        .map((e) => {
          if (!e.sizes) return 0;
          const colorIncluded = e.sizes.find((ss) => ss.id === s.id);
          if (colorIncluded) return e.value;
          return 0;
        })
        .reduce((t, n) => t + n, 0);
      if (extra) return { ...s, extra };
      return s;
    });
  }
  return result;
};

const sortFavorites = (items, favorites) => {
  if (!favorites) return items;
  const favs = favorites.map((ref) => ref.id);
  return sortBy(
    items.map((i) => {
      const isFavorite = favs.includes(i.id);
      i.isFavorite = isFavorite;
      i.order = isFavorite ? 0 : i.order;
      return i;
    }),
    ["order"]
  );
};

const INITIAL_ITEMS_FETCH_LIMIT = 10;
const DEFAULT_QUERY_PAGE = 0;

const CreateOrder = ({ handleChangeNavComponent, handleSetLayoutLoader }) => {
  const classes = useStyles();
  const history = useHistory();
  const firebase = useFirebaseContext();
  const { profile, data: userData } = useUserContext();
  const {
    categories = [],
    colors,
    counters,
    types = [],
    sizes,
    extras = [],
  } = useConfigContext();
  const [, alertDispatch] = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [limit, setLimit] = useState(INITIAL_ITEMS_FETCH_LIMIT);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState(null);
  const {
    order,
    loading: orderLoading,
    addItems,
    removeItem,
    removeItemBySortKey,
  } = useOrder(firebase);

  /**
   * @param query: Search query text
   * @param limit: Query limit
   * @param filters: Filter object
   */
  const fetchItems = useCallback(async (query, limit, filters) => {
    setLoading(true);
    const { hits, nbHits } = await searchInIndex(
      query || "",
      filters
        ? { filters, hitsPerPage: limit, page: DEFAULT_QUERY_PAGE }
        : { hitsPerPage: limit, page: DEFAULT_QUERY_PAGE },
      process.env.REACT_APP_ALGOLIA_ITEMS_INDEX_NAME
    );
    setLoading(false);
    setItems(hits);
    setTotalItems(nbHits);
  }, []);

  const debounceFetchItems = useRef(
    debounce((s, l, f) => fetchItems(s, l, f), 700)
  ).current;

  useEffect(() => {
    const typeList = types.map((t) => ({
      label: t.name,
      id: `typesId_${t.id}`,
    }));
    const categoryList = categories.map((c) => ({
      label: c.name,
      id: `categoriesId_${c.id}`,
    }));
    handleChangeNavComponent(
      <Search
        selectOptions={
          !isEmpty(categoryList) || !isEmpty(typeList)
            ? { types: typeList, categories: categoryList }
            : null
        }
        selectLabel="Filters"
        onSearchChange={(value) => setSearch(value)}
        onSelectChange={(key) => {
          if (key === "all") {
            setFilter(null);
          } else {
            const [prop, id] = key.split("_");
            let value;
            if (prop === "typesId") {
              value = types.find((t) => t.id === id).name;
            }
            if (prop === "categoriesId") {
              value = categories.find((t) => t.id === id).name;
            }
            setFilter(`${prop}:${value}`);
          }
        }}
      />
    );
  }, [handleChangeNavComponent, categories, types]);

  const handleOnDownloadOrder = useCallback(
    async (e) => {
      e.preventDefault();
      setDownloadLoading(true);
      const orderWindow = window.open("");
      try {
        const orderContent = await firebase.downloadOrder(order.id);
        orderWindow.document.write(orderContent);
        orderWindow.document.close();
        orderWindow.focus();
        orderWindow.onload = () => {
          orderWindow.print();
        };
        orderWindow.onafterprint = (_) => {
          orderWindow.close();
        };
      } catch (error) {
        orderWindow.close();
        alertDispatch({
          type: "show",
          payload: {
            severity: "error",
            text: error.message || "Something went wrong.",
          },
        });
      }
      setDownloadLoading(false);
    },
    [firebase, order, alertDispatch]
  );

  const handleOnAddItem = useCallback(
    async (itemId, quantity, colorId, selectedSizes) => {
      const itemList = selectedSizes.map((sizeId) => {
        const sortKey = firebase.getOrderItemSortKey(itemId, colorId, sizeId);
        const item = firebase.item(itemId);
        const color = firebase.color(colorId);
        const size = firebase.size(sizeId);
        return {
          color,
          id: item,
          size,
          quantity,
          sortKey,
        };
      });
      await addItems(itemList);
      alertDispatch({
        type: "show",
        payload: { text: "Item added successfully", severity: "success" },
      });
    },
    [firebase, alertDispatch, addItems]
  );

  const handleToggleModal = useCallback(() => {
    setModalOpen((o) => !o);
  }, []);

  const handleToggleFavorite = useCallback(
    async (itemId) => {
      if (!profile) return;
      setLoading(true);
      await firebase.toggleFavorite(profile.uid, itemId);
      setLoading(false);
    },
    [profile, firebase]
  );

  const handleCompleteOrder = useCallback(() => {
    history.push(ROUTE_NAMES.COMPLETE.replace(":id", order.id));
  }, [history, order]);

  useEffect(() => {
    handleSetLayoutLoader(loading || orderLoading);
  }, [loading, orderLoading, handleSetLayoutLoader]);

  useEffect(() => {
    debounceFetchItems(search, limit, filter);
  }, [debounceFetchItems, search, limit, filter]);

  useEffect(() => {
    fetchItems(search, limit, filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderNodeElement = (
    <div className={classes.orderWrapper}>
      <Hidden mdUp>
        {(loading || orderLoading) && (
          <LinearProgress aria-describedby="navbar-loader" />
        )}
      </Hidden>
      {order && (
        <OrderSummary
          edit={true}
          items={order.parsedItems}
          total={order.total}
          tax={order.tax}
          subtotal={order.subtotal}
          charge={order.charge}
          colors={colors}
          sizes={sizes}
          loading={loading || orderLoading}
          downloadLoading={downloadLoading}
          onRemoveItem={removeItem}
          onRemoveItemBySortKey={removeItemBySortKey}
          onDownloadOrder={handleOnDownloadOrder}
          handleClose={handleToggleModal}
        >
          <div className={classes.button}>
            <Button
              disabled={orderLoading || loading}
              color="secondary"
              size="large"
              variant="contained"
              onClick={handleCompleteOrder}
            >
              Charge
            </Button>
          </div>
        </OrderSummary>
      )}
      {!order && !orderLoading && (
        <Paper square className={classes.emptyOrder}>
          <EmptyOrderIcon />
          <div>
            <Typography variant="subtitle1">
              You have not added products yet
            </Typography>
            <Typography gutterBottom variant="body1">
              Start adding products
            </Typography>
            <Hidden mdUp>
              <Button
                color="default"
                variant="contained"
                onClick={handleToggleModal}
              >
                Back
              </Button>
            </Hidden>
          </div>
        </Paper>
      )}
    </div>
  );

  const fetchData = useCallback(() => {
    const newLimit = limit + 10;
    if (newLimit < totalItems) {
      setLimit(newLimit);
    }
  }, [limit, totalItems]);

  const sortedItems = sortFavorites(items, get(["favorites"], userData));

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={12} md={8} className={classes.itemsGrid}>
          <Paper className={classes.paper} square variant="outlined">
            {items.length === 0 && !loading && (
              <Typography
                className={classes.noItems}
                variant="body1"
                color="textSecondary"
              >
                No items found
              </Typography>
            )}
            {items && items.length > 0 && (
              <InfiniteScroll
                dataLength={sortedItems.length}
                next={fetchData}
                hasMore={
                  counters
                    ? sortedItems.length < counters.items.numberOfDocs
                    : true
                }
                loader={
                  loading && (
                    <Typography
                      className={classes.infiniteScrollLoader}
                      align="center"
                      variant="body1"
                    >
                      Loading items...
                    </Typography>
                  )
                }
              >
                {sortedItems.map((item) => (
                  <ShoppingItemControl
                    key={item.id}
                    {...item}
                    // Force remove discount temporally
                    discount={null}
                    colors={parseAvailableItemColors(item, colors, extras)}
                    sizes={parseAvailableItemSizes(item, sizes, extras)}
                    onAddItem={handleOnAddItem}
                    onToggleFavorite={handleToggleFavorite}
                    loading={loading}
                  />
                ))}
              </InfiniteScroll>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.orderGrid}>
          <Hidden smDown>{orderNodeElement}</Hidden>
          <Hidden mdUp>
            <div className={classes.footer} onClick={handleToggleModal}>
              <Typography className={classes.footerText} variant="body1">
                <CustomIcon name="ShoppingBag" fontSize="small" />{" "}
                {order ? order.quantity : 0} items /{" "}
                {order ? currencyFormatter.format(order.total) : "$0.00"}
              </Typography>
              <div>
                <Typography className={classes.footerText} variant="body1">
                  View order <CustomIcon name="ChevronRight" fontSize="small" />
                </Typography>
              </div>
            </div>
            <Modal open={modalOpen}>
              <Slide direction="up" in={modalOpen} mountOnEnter unmountOnExit>
                {orderNodeElement}
              </Slide>
            </Modal>
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  grid: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 74px - 64px)",
    },
  },
  itemsGrid: {
    height: "calc(100% - 48px)",
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  paper: {
    minHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      border: "none",
    },
  },
  cart: {
    height: "100%",
    width: "100%",
  },
  emptyOrder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    "& > *": {
      color: "#808080",
      margin: theme.spacing(1, 0),
      textAlign: "center",
    },
  },
  noItems: {
    padding: theme.spacing(3),
  },
  footer: {
    color: theme.palette.common.white,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    bottom: 0,
    boxShadow: "10px 11px 18px 6px #CCCCCC",
    cursor: "pointer",
    display: "flex",
    left: 0,
    padding: theme.spacing(1.5, 2),
    position: "fixed",
    width: "100%",
  },
  footerText: {
    flex: 1,
    "& > svg": {
      verticalAlign: "middle",
    },
  },
  orderWrapper: {
    height: "100%",
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      overflowY: "inherit",
    },
  },
  orderGrid: {
    height: 48,
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      width: "100%",
      right: 0,
      bottom: 0,
      height: "calc(100% - 140px)",
    },
  },
  button: {
    textAlign: "right",
  },
  infiniteScrollLoader: {
    margin: theme.spacing(1, 0),
  },
}));

export default CreateOrder;
