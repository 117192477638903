import React, { useState, useCallback } from "react";
import {
  makeStyles,
  TextField,
  MenuItem,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import CustomIcon from "./CustomIcon";
import PropTypes from "prop-types";

const Search = ({
  selectLabel,
  selectOptions = { types: [], categories: [] },
  onSearchChange,
  onSelectChange,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState("");
  const [search, setSearch] = useState("");

  const handleSearchChange = useCallback(
    (e) => {
      setSearch(e.target.value);
      if (onSearchChange) onSearchChange(e.target.value);
    },
    [onSearchChange]
  );

  const handleSelectCategory = useCallback(
    (e) => {
      setSelected(e.target.value);
      if (onSelectChange) onSelectChange(e.target.value);
    },
    [onSelectChange]
  );

  return (
    <div className={classes.root}>
      <TextField
        placeholder="Search"
        variant="outlined"
        className={classes.inputWidth}
        onChange={handleSearchChange}
        value={search}
        InputProps={{
          className: classes.input,
          startAdornment: (
            <InputAdornment position="start">
              <CustomIcon name="Search" />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        select
        label={selectLabel}
        value={selected}
        disabled={!selectOptions}
        onChange={handleSelectCategory}
        variant="outlined"
        className={classes.selectInput}
        SelectProps={{
          classes: {
            root: classes.selectRoot,
            select: classes.select,
          },
        }}
        InputLabelProps={{
          classes: {
            formControl: Boolean(selected) ? null : classes.selectInputControl,
          },
        }}
      >
        <MenuItem className="capitalize" value="all">
          <Typography variant="inherit" className="capitalize">
            All
          </Typography>
        </MenuItem>
        {selectOptions &&
          selectOptions.categories && selectOptions.categories.map((category) => (
            <MenuItem
              className="capitalize"
              key={category.id}
              value={category.id}
            >
              <Typography variant="inherit" className="capitalize">
                {category.label}
              </Typography>
            </MenuItem>
          ))}
        {selectOptions &&
          selectOptions.types && selectOptions.types.map((type) => (
            <MenuItem className="capitalize" key={type.id} value={type.id}>
              <Typography variant="inherit" className="capitalize">
                {type.label}
              </Typography>
            </MenuItem>
          ))}
      </TextField>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(2, 0),
    width: "100%",
    "& > *": {
      margin: theme.spacing(0, 1),
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      margin: theme.spacing(0, 2),
      padding: "unset",
    },
  },
  input: {
    height: 40,
  },
  inputWidth: {
    width: "100%",
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 1),
      width: "30ch",
    },
  },
  selectInput: {
    width: "100%",
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 1),
      width: "20ch",
    },
  },
  selectRoot: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  select: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    display: "flex",
    justifyContent: "flex-start",
  },
  selectInputControl: {
    top: -6,
  },
}));

Search.propTypes = {
  selectLabel: PropTypes.string,
  selectOptions: PropTypes.exact({
    categories: PropTypes.array,
    types: PropTypes.array,
  }),
  onSearchChange: PropTypes.func,
  onSelectChange: PropTypes.func,
};

export default Search;
