import {
  Button,
  Grid,
  Hidden,
  LinearProgress,
  makeStyles,
  Modal,
  Paper,
  Slide,
  Typography,
  useMediaQuery,
  IconButton,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as EmptyCardsIcon } from "../assets/icons/card-empty.svg";
import { ReactComponent as PdfIcon } from "../assets/icons/pdf.svg";
import ErrorImage from "../assets/images/error.png";
import SuccessImage from "../assets/images/success.png";
import PendingImage from "../assets/images/pending.png";
import Stores from "./Stores";
import { useConfigContext } from "../contexts/Config";
import { useFirebaseContext } from "../contexts/Firebase";
import { useUserContext } from "../contexts/User";
import { useAlertContext } from "../contexts/Alert";
import CardForm from "../components/CardForm";
import CreditCard from "../components/CreditCard";
import CustomIcon from "../components/CustomIcon";
import OrderStepper from "../components/OrderStepper";
import OrderSummary from "../components/OrderSummary";
import PremiumAccountCard from "../components/PremiumAccountCard";
import AlertDialog from "../components/AlertDialog";
import ROUTE_NAMES from "../config/routes";
import currencyFormatter from "../utils/currency";
import { PaymentMethods, ShippingMethods } from "../utils/common";
import get from "../utils/getter";
import * as yup from "yup";
import { FIRESTORE_PERMISSION_DENIED_ERROR_CODE } from "../services/firebase";
import useOrder from "../hooks/order.hook";
import { calculateFreight } from "../services/freight";

const COMMENTS_LENGTH_LIMIT = 200;
const PURCHASE_ORDER_LENGTH_LIMIT = 20;
const PLACED_ORDER_STATUS = "placed";
const REVIEWING_ORDER_STATUS = "reviewing";
const TAX_VALUE = 0.1;

const getBackButtonLabel = (step) => {
  switch (step) {
    case 0:
      return "Return to store";
    case 1:
      return "Back to shipping details";
    case 2:
      return "Back to payment details";
    default:
      return "Return to store";
  }
};

const getAlertProps = (status, error, classes) => {
  if (error) {
    return {
      title: "Uh oh.",
      message: (
        <Typography variant="body1">
          Something weird happening. <br />
          Keep calm and try again.
        </Typography>
      ),
      icon: <img height="auto" width="100%" src={ErrorImage} alt="Error" />,
      color: "secondary",
      showCloseAlertButton: true,
      ModalProps: {
        disableBackdropClick: false,
        disableEscapeKeyDown: false,
      },
    };
  }
  switch (status) {
    case PLACED_ORDER_STATUS:
      return {
        title: "Woo Hoo!",
        message: (
          <Typography variant="body1">
            Your order was sent. <br />
            Bask in the glory.
          </Typography>
        ),
        icon: (
          <img height="auto" width="100%" src={SuccessImage} alt="Success" />
        ),
        color: "primary",
        showCloseAlertButton: false,
        ModalProps: {
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        },
      };
    case REVIEWING_ORDER_STATUS:
      return {
        classes: { button: classes.alertButtonPending },
        title: "Hmm!",
        message: (
          <Typography variant="body1">
            Your order needs revision. <br />
            Keep calm and give us some time.
          </Typography>
        ),
        icon: (
          <img height="auto" width="100%" src={PendingImage} alt="Pending" />
        ),
        showCloseAlertButton: false,
        ModalProps: {
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        },
      };
    default:
      return null;
  }
};

const CompleteOrder = ({ handleChangeNavComponent, handleSetLayoutLoader }) => {
  const classes = useStyles();
  const history = useHistory();
  let { id } = useParams();
  const firebase = useFirebaseContext();
  const { data: userData } = useUserContext();
  const { colors, sizes } = useConfigContext();
  const [, alertDispatch] = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [freightLoading, setFreightLoading] = useState(false);
  const [freight, setFreight] = useState(0);
  const [step, setStep] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [payment, setPayment] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [cardFormValid, setCardFormValid] = useState(false);
  const [comments, setComments] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [commentsError, setCommentsError] = useState(null);
  const [purchaseOrderError, setPurchaseOrderError] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const desktop = useMediaQuery("(min-width:540px)");
  const uploadInputRef = useRef(null);
  const stepFooterRef = useRef(null);
  const { cards = [] } = userData;
  const {
    order,
    loading: orderLoading,
    loaded: orderLoaded,
    error: orderError,
    finishOrder,
  } = useOrder(firebase, id);

  const handleToggleModal = useCallback(() => {
    setModalOpen((o) => !o);
  }, []);

  const handleOnEdit = useCallback(() => {
    history.push(ROUTE_NAMES.CREATE_ORDER);
  }, [history]);

  const handleOnDownloadOrder = useCallback(
    async (e) => {
      e.preventDefault();
      setDownloadLoading(true);
      const orderWindow = window.open("");
      try {
        const orderContent = await firebase.downloadOrder(id);
        orderWindow.document.write(orderContent);
        orderWindow.document.close();
        orderWindow.focus();
        orderWindow.onload = () => {
          orderWindow.print();
        };
        orderWindow.onafterprint = (_) => {
          orderWindow.close();
        };
      } catch (error) {
        orderWindow.close();
        alertDispatch({
          type: "show",
          payload: {
            severity: "error",
            text: error.message || "Something went wrong.",
          },
        });
      }
      setDownloadLoading(false);
    },
    [firebase, id, alertDispatch]
  );

  const setupSteps = useCallback(async () => {
    try {
      if (order.status === "editing") {
        setStep(0);
        setCanEdit(true);
      } else {
        setShipping(order.shipping);
        setPayment(order.payment);
        setComments(order.comments || "");
        setPurchaseOrder(order.purchaseOrder || "");
        setStep(2);
        setCanEdit(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [order]);

  const sendOrder = useCallback(async () => {
    setLoading(true);
    try {
      const getPaymentMethodData = async () => {
        if (!payment) return null;
        switch (payment.method) {
          case PaymentMethods.PREMIUM:
            return { method: PaymentMethods.PREMIUM, data: null };
          case PaymentMethods.CREDIT_CARD:
            return { method: PaymentMethods.CREDIT_CARD, data: payment.data };
          case PaymentMethods.DIRECT_DEPOSIT:
            const path = `orders/${order.id}/payment/${receipt.name}`;
            const url = await firebase.uploadFile(path, receipt);
            return {
              method: PaymentMethods.DIRECT_DEPOSIT,
              data: {
                name: receipt.name,
                type: receipt.type,
                url,
              },
            };
          default:
            return null;
        }
      };
      const confirmData = {
        payment: await getPaymentMethodData(),
        shipping,
        comments,
        purchaseOrder,
      };
      console.log("Confirm data: ", confirmData);
      await finishOrder(confirmData);
      setError(null);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
    setOpenAlert(true);
    setLoading(false);
  }, [
    firebase,
    order,
    shipping,
    payment,
    receipt,
    comments,
    purchaseOrder,
    finishOrder,
  ]);

  const handleCloseAlert = useCallback(() => {
    setOpenAlert(false);
  }, []);

  const handleConfirmAlert = useCallback(() => {
    setOpenAlert(false);
    history.replace(ROUTE_NAMES.ORDERS);
  }, [history]);

  const handleNextStep = useCallback(() => {
    if (!canEdit) return;
    setStep((s) => {
      if (s === 2) {
        sendOrder();
        return s;
      }
      return s + 1;
    });
  }, [sendOrder, canEdit]);

  const handleSelectPickup = useCallback(() => {
    setShipping({ method: ShippingMethods.PICKUP });
    setFreight(0);
  }, []);

  const handleSelectStore = useCallback(
    async ({
      address,
      city,
      country,
      email,
      id: storeID,
      phone,
      postcodeID,
      state,
      zip,
      isStore,
      name,
    }) => {
      const shippingData = {
        address,
        city,
        country,
        email,
        phone,
        postcodeID,
        state,
        zip,
        isStore,
        name,
      };
      if (isStore) {
        shippingData.ref = storeID;
      }
      setShipping(shippingData);
      stepFooterRef.current.scrollIntoView({ behavior: "smooth" });
      setFreightLoading(true);
      try {
        const token = await firebase.getAuthToken();
        const { freight: val } = await calculateFreight(
          {
            orderID: id,
            to: shippingData.city,
            postcodeTo: shippingData.zip,
          },
          token
        );
        setFreight(val);
      } catch (error) {
        console.log("Freight error: ", error);
        alertDispatch({
          type: "show",
          payload: {
            text:
              error.message ||
              "Something went wrong calculating the freight. You can continue anyway.",
            severity: "warning",
          },
        });
      }
      setFreightLoading(false);
    },
    [id, alertDispatch, firebase]
  );

  const handleSelectCard = useCallback((c) => {
    setPayment({
      method: PaymentMethods.CREDIT_CARD,
      data: { ...c, isNew: false },
    });
    setReceipt(null);
    stepFooterRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleBack = useCallback(() => {
    if (!canEdit) return;
    if (step === 0) {
      history.replace(ROUTE_NAMES.CREATE_ORDER);
    } else {
      setStep((s) => s - 1);
    }
  }, [step, history, canEdit]);

  const handleOpenCardForm = useCallback(() => {
    setPayment({
      method: PaymentMethods.CREDIT_CARD,
      data: { isNew: true },
    });
    setReceipt(null);
  }, []);

  const handleShowUploadInput = useCallback(() => {
    setPayment({ method: PaymentMethods.DIRECT_DEPOSIT });
    stepFooterRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleUpdateInformation = useCallback((formData) => {
    setPayment({
      method: PaymentMethods.CREDIT_CARD,
      data: { ...formData.values, isNew: true },
    });
    setCardFormValid(formData.isValid);
  }, []);

  const onDrop = useCallback((e) => {
    const file = get(["target", "files", 0], e);
    if (file) {
      setReceipt(file);
      stepFooterRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleClickUploadZone = useCallback(
    (e) => {
      uploadInputRef.current.click();
    },
    [uploadInputRef]
  );

  const handleRemoveReceipt = useCallback(() => {
    setReceipt(null);
  }, []);

  const handleChangeComments = useCallback((e) => {
    try {
      const val = e.target.value;
      yup
        .string()
        .max(COMMENTS_LENGTH_LIMIT, `Max ${COMMENTS_LENGTH_LIMIT} characters`)
        .optional()
        .validateSync(val);
      setComments(val);
      setCommentsError(null);
    } catch (error) {
      setCommentsError(error.message);
    }
  }, []);

  const handleChangePurchaseOrder = useCallback((e) => {
    try {
      const val = e.target.value;
      yup
        .string()
        .max(
          PURCHASE_ORDER_LENGTH_LIMIT,
          `Max ${PURCHASE_ORDER_LENGTH_LIMIT} characters`
        )
        .optional()
        .validateSync(val);
      setPurchaseOrder(val);
      setPurchaseOrderError(null);
    } catch (error) {
      setPurchaseOrderError(error.message);
    }
  }, []);

  useEffect(() => {
    if (orderError) {
      let message = "Something went wrong. Please try again.";
      if (orderError.code === FIRESTORE_PERMISSION_DENIED_ERROR_CODE) {
        message = "Your not allowed to see this resource.";
      }
      alertDispatch({
        type: "show",
        payload: {
          severity: "error",
          text: message,
        },
      });
    }
  }, [alertDispatch, orderError]);

  useEffect(() => {
    if (!id) return history.replace(ROUTE_NAMES.CREATE_ORDER);
    handleChangeNavComponent("Complete your order");
    if (
      get(["preferences", "shippingMethod"], userData) ===
      ShippingMethods.PICKUP
    ) {
      setShipping({ method: ShippingMethods.PICKUP });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSetLayoutLoader(loading || freightLoading);
  }, [loading, freightLoading, handleSetLayoutLoader]);

  useEffect(() => {
    if (orderLoaded) {
      setupSteps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderLoaded]);

  const orderNodeElement = (
    <div className={classes.orderWrapper}>
      <Hidden mdUp>
        {(loading || orderLoading) && (
          <LinearProgress aria-describedby="navbar-loader" />
        )}
      </Hidden>
      {order && (
        <OrderSummary
          edite={false}
          showEditButton={canEdit}
          charge={order.charge}
          items={order.parsedItems}
          total={order.freight ? order.total : order.total + freight}
          tax={order.freight ? order.tax : order.tax + freight * TAX_VALUE}
          subtotal={order.subtotal}
          colors={colors}
          sizes={sizes}
          freight={order.freight || freight}
          loading={loading || orderLoading}
          downloadLoading={downloadLoading}
          onDownloadOrder={handleOnDownloadOrder}
          handleClose={handleToggleModal}
          onEdit={handleOnEdit}
        />
      )}
    </div>
  );

  const isValidPayment = () => {
    if (!payment) return false;
    if (payment.method === PaymentMethods.PREMIUM) return true;
    if (payment.method === PaymentMethods.DIRECT_DEPOSIT) {
      return !!receipt;
    }
    if (payment.method === PaymentMethods.CREDIT_CARD) {
      if (payment.data.isNew) {
        return cardFormValid;
      } else {
        return !!payment.data.number;
      }
    }
    return false;
  };

  const actionButtons = (
    <>
      <Button
        className={classes.actionButton}
        onClick={handleBack}
        color="primary"
        variant="outlined"
        size={desktop ? "large" : "small"}
        disabled={!canEdit}
      >
        {getBackButtonLabel(step)}
      </Button>
      <Button
        className={classes.actionButton}
        onClick={handleNextStep}
        color="secondary"
        variant="contained"
        size={desktop ? "large" : "small"}
        disabled={
          (step === 0 && !shipping) ||
          (step === 1 && !isValidPayment()) ||
          !canEdit
        }
      >
        {step !== 2 && "Continue"}
        {step === 2 && "Finish"}
      </Button>
    </>
  );

  const cardsEmptyState = (
    <div className={classes.empty}>
      <EmptyCardsIcon />
      <div>
        <Typography variant="subtitle1">
          You have not added credit cards yet
        </Typography>
        <Typography variant="body1">Start adding credit cards</Typography>
      </div>
    </div>
  );

  const storeSelected = shipping
    ? shipping.isStore
      ? shipping.ref
      : shipping.method === ShippingMethods.PICKUP
      ? ShippingMethods.PICKUP
      : "customer"
    : null;

  return (
    <Grid container alignItems="stretch" className={classes.root}>
      <AlertDialog
        handleClose={handleCloseAlert}
        handleConfirm={error ? handleCloseAlert : handleConfirmAlert}
        open={openAlert}
        {...getAlertProps(order ? order.status : null, error, classes)}
      />
      <Grid item xs={12} sm={12} md={8} className={classes.itemsGrid}>
        <Paper className={classes.paper} square variant="outlined">
          <OrderStepper step={step} />
          <div className={classes.stepContent}>
            {step === 0 && (
              <Stores
                classes={{
                  selectableTitleBox: classes.storesTitles,
                  fab: classes.storesFab,
                }}
                selectable
                selected={storeSelected}
                selectableDisabled={freightLoading}
                onPickupSelect={handleSelectPickup}
                onSelect={handleSelectStore}
                handleSetLayoutLoader={handleSetLayoutLoader}
              />
            )}
            {step === 1 && (
              <>
                <Typography gutterBottom variant="subtitle2">
                  Choose a payment method to continue.
                </Typography>
                <Typography variant="body1">Your payment methods</Typography>
                <div className={classes.cards}>
                  {userData && userData.premium && (
                    <div
                      className={clsx([
                        classes.selectable,
                        {
                          [classes.selected]:
                            payment?.method === PaymentMethods.PREMIUM,
                        },
                      ])}
                      onClick={handleSelectCard.bind(
                        null,
                        PaymentMethods.PREMIUM
                      )}
                    >
                      <PremiumAccountCard
                        classes={{ root: classes.premiumCard }}
                      />
                    </div>
                  )}
                  {cards && cards.length === 0 && !loading && cardsEmptyState}
                  {cards &&
                    cards.map((c) => (
                      <div
                        className={clsx([
                          classes.selectable,
                          {
                            [classes.selected]:
                              payment?.data?.number === c.number,
                          },
                        ])}
                        key={c.number}
                        onClick={handleSelectCard.bind(null, c)}
                      >
                        <CreditCard
                          type={c.type}
                          number={c.number}
                          exp={c.exp}
                        />
                      </div>
                    ))}
                </div>
                <Typography gutterBottom variant="body1">
                  Other payment methods
                </Typography>
                <div className={classes.methods}>
                  <Button
                    className={clsx([
                      classes.method,
                      {
                        [classes.selectedMethod]:
                          payment?.method === PaymentMethods.CREDIT_CARD &&
                          payment?.data?.isNew,
                      },
                    ])}
                    onClick={handleOpenCardForm}
                    variant="outlined"
                  >
                    Credit card
                  </Button>
                  <Button
                    className={clsx([
                      classes.method,
                      {
                        [classes.selectedMethod]:
                          payment?.method === PaymentMethods.DIRECT_DEPOSIT,
                      },
                    ])}
                    onClick={handleShowUploadInput}
                    variant="outlined"
                  >
                    Direct deposit
                  </Button>
                </div>
                <div className={classes.paymentForm}>
                  {payment?.method === PaymentMethods.CREDIT_CARD &&
                    payment?.data?.isNew && (
                      <CardForm
                        collapseInputs
                        handleUpdateData={handleUpdateInformation}
                      />
                    )}
                  {payment?.method === PaymentMethods.DIRECT_DEPOSIT && (
                    <div
                      className={clsx([
                        classes.dropzone,
                        { [classes.filled]: Boolean(receipt) },
                      ])}
                      onClick={receipt ? null : handleClickUploadZone}
                    >
                      <input
                        hidden
                        accept="image/jpeg,image/jpg,image/png,application/pdf"
                        ref={uploadInputRef}
                        type="file"
                        onChange={onDrop}
                      />
                      {receipt && (
                        <>
                          {receipt.type === "application/pdf" && (
                            <PdfIcon height={38} width={33} />
                          )}
                          {(receipt.type === "image/jpg" ||
                            receipt.type === "image/jpeg" ||
                            receipt.type === "image/png") && (
                            <CustomIcon name="Image" />
                          )}
                          <div className={classes.fileName}>
                            <Typography variant="body1">
                              {receipt.name}
                            </Typography>
                            <Typography variant="caption">Uploaded</Typography>
                          </div>
                          <IconButton onClick={handleRemoveReceipt}>
                            <CustomIcon name="X" />
                          </IconButton>
                        </>
                      )}
                      {!receipt && (
                        <>
                          <CustomIcon fontSize="large" name="UploadCloud" />
                          <Typography variant="body1">
                            You can upload files by{" "}
                            <Typography
                              color="primary"
                              component="span"
                              variant="body2"
                            >
                              Click here
                            </Typography>
                          </Typography>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
            {step === 2 && (
              <div className={classes.confirmation}>
                <div className={classes.section}>
                  <Typography gutterBottom color="primary" variant="subtitle2">
                    Shipping Details
                  </Typography>
                  <div className={classes.info}>
                    <Typography variant="body2" component="span" align="left">
                      {shipping?.method === ShippingMethods.PICKUP
                        ? "Pickup"
                        : "Store"}
                    </Typography>
                    <Typography
                      className="capitalize"
                      variant="body1"
                      component="span"
                      align="right"
                    >
                      {shipping?.method === ShippingMethods.PICKUP
                        ? "UGG Australia"
                        : shipping.name}
                    </Typography>
                  </div>
                  <div className={classes.info}>
                    <Typography variant="body2" component="span" align="left">
                      Address
                    </Typography>
                    <Typography
                      className="capitalize"
                      variant="body1"
                      component="span"
                      align="right"
                    >
                      {shipping?.method === ShippingMethods.PICKUP
                        ? "31-35 Gilberston Road"
                        : shipping.address}
                    </Typography>
                  </div>
                  <div className={classes.info}>
                    <Typography variant="body2" component="span" align="left">
                      City/Suburb
                    </Typography>
                    <Typography
                      className="capitalize"
                      variant="body1"
                      component="span"
                      align="right"
                    >
                      {shipping?.method === ShippingMethods.PICKUP
                        ? "Laverton North"
                        : shipping.city}
                    </Typography>
                  </div>
                  <div className={classes.info}>
                    <Typography variant="body2" component="span" align="left">
                      Email
                    </Typography>
                    <Typography variant="body1" component="span" align="right">
                      {shipping?.method === ShippingMethods.PICKUP
                        ? "sales@uggaustralia.com.au"
                        : shipping.email}
                    </Typography>
                  </div>
                  <div className={classes.info}>
                    <Typography variant="body2" component="span" align="left">
                      Phone
                    </Typography>
                    <Typography variant="body1" component="span" align="right">
                      {shipping?.method === ShippingMethods.PICKUP
                        ? "+613 93865544"
                        : shipping.phone}
                    </Typography>
                  </div>
                </div>
                <div className={classes.section}>
                  <Typography gutterBottom color="primary" variant="subtitle2">
                    Payment Details
                  </Typography>
                  <div className={classes.info}>
                    <Typography variant="body2" component="span" align="left">
                      Payment method
                    </Typography>
                    <Typography variant="body1" component="span" align="right">
                      {payment ? payment.method : ""}
                    </Typography>
                  </div>
                  {receipt && (
                    <div className={classes.info}>
                      <Typography variant="body2" component="span" align="left">
                        Receipt
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        align="right"
                      >
                        {receipt.name}
                      </Typography>
                    </div>
                  )}
                  {payment?.method === PaymentMethods.CREDIT_CARD && (
                    <>
                      <div className={classes.info}>
                        <Typography
                          variant="body2"
                          component="span"
                          align="left"
                        >
                          Name
                        </Typography>
                        <Typography
                          className="uppercase"
                          variant="body1"
                          component="span"
                          align="right"
                        >
                          {payment?.data?.holderName}
                        </Typography>
                      </div>
                      <div className={classes.info}>
                        <Typography
                          variant="body2"
                          component="span"
                          align="left"
                        >
                          Card number
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          align="right"
                        >
                          **** **** ****{" "}
                          {payment?.data?.number.substr(
                            payment.data.number.length - 4
                          )}
                        </Typography>
                      </div>
                    </>
                  )}
                </div>
                <Typography variant="body1">Purchase order</Typography>
                <TextField
                  variant="outlined"
                  type="text"
                  value={purchaseOrder}
                  onChange={handleChangePurchaseOrder}
                  fullWidth
                  error={Boolean(purchaseOrderError)}
                  helperText={Boolean(purchaseOrderError) && purchaseOrderError}
                  InputProps={{ className: classes.input }}
                  disabled={!canEdit}
                />
                <Typography variant="body1">Additional comments</Typography>
                <TextField
                  variant="outlined"
                  type="text"
                  value={comments}
                  onChange={handleChangeComments}
                  fullWidth
                  error={Boolean(commentsError)}
                  helperText={Boolean(commentsError) && commentsError}
                  InputProps={{ className: classes.input }}
                  disabled={!canEdit}
                />
              </div>
            )}
          </div>
          <div ref={stepFooterRef} className={classes.stepsFooter}>
            {actionButtons}
          </div>
        </Paper>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} md={4} className={classes.orderGrid}>
          {orderNodeElement}
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.footer} onClick={handleToggleModal}>
          <Typography className={classes.footerText} variant="body1">
            <CustomIcon name="ShoppingBag" fontSize="small" />{" "}
            {order ? order.quantity : 0} items /{" "}
            {order ? currencyFormatter.format(order.total) : "$0.00"}
          </Typography>
          <div>
            <Typography className={classes.footerText} variant="body1">
              View order <CustomIcon name="ChevronRight" fontSize="small" />
            </Typography>
          </div>
        </div>
        <Modal open={modalOpen}>
          <Slide direction="up" in={modalOpen} mountOnEnter unmountOnExit>
            {orderNodeElement}
          </Slide>
        </Modal>
      </Hidden>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  itemsGrid: {
    marginBottom: 48,
    [theme.breakpoints.up("md")]: {
      marginBottom: "unset",
    },
  },
  paper: {
    borderTop: "none",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cart: {
    height: "100%",
    width: "100%",
  },
  footer: {
    display: "flex",
    color: theme.palette.common.white,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    bottom: 0,
    boxShadow: "10px 11px 18px 6px #CCCCCC",
    cursor: "pointer",
    left: 0,
    padding: theme.spacing(1.5, 2),
    position: "fixed",
    width: "100%",
    zIndex: 2,
  },
  footerText: {
    flex: 1,
    "& > svg": {
      verticalAlign: "middle",
    },
  },
  orderWrapper: {
    height: "100vh",
    overflowY: "scroll",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      overflowY: "inherit",
    },
  },
  orderGrid: {
    height: 48,
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      width: "100%",
      right: 0,
      top: 140,
      height: "calc(100% - 140px)",
    },
  },
  stepContent: {
    flexGrow: 1,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 4),
    },
  },
  stepsFooter: {
    alignItems: "center",
    borderTopColor: theme.palette.divider,
    borderTopStyle: "solid",
    borderTopWidth: 1,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 4),
    },
  },
  empty: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    width: "100%",
  },
  cards: {
    alignItems: "stretch",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
  },
  selectable: {
    borderColor: "transparent",
    borderRadius: 20,
    borderStyle: "solid",
    borderWidth: 2,
    cursor: "pointer",
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      flexBasis: 230,
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(1, 2, 1, 0),
    },
  },
  selected: {
    borderColor: "#333",
  },
  methods: {
    display: "flex",
    alignItems: "center",
  },
  method: {
    borderRadius: 10,
    color: theme.palette.grey[500],
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(1.5, 8),
    marginRight: theme.spacing(2),
    textTransform: "none",
  },
  selectedMethod: {
    borderColor: theme.palette.grey[900],
    color: theme.palette.grey[900],
  },
  paymentForm: {
    marginTop: theme.spacing(4),
  },
  storesTitles: {
    marginRight: theme.spacing(6),
  },
  premiumCard: {
    height: "100%",
  },
  actionButton: {
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      flexGrow: 0,
      minWidth: 200,
    },
    "&:first-child": {
      marginRight: theme.spacing(1),
    },
    "&:last-child": {
      marginLeft: theme.spacing(1),
    },
  },
  storesFab: {
    top: 30,
  },
  dropzone: {
    backgroundColor: theme.palette.background.default,
    border: "3px dashed #eeeeee",
    borderRadius: 10,
    cursor: "pointer",
    marginBottom: theme.spacing(4),
    minHeight: 135,
    padding: theme.spacing(4),
    textAlign: "center",
  },
  filled: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    cursor: "unset",
  },
  fileName: {
    flexGrow: 1,
    margin: theme.spacing(0, 1),
  },
  info: {
    display: "flex",
    justifyContent: "space-between",
  },
  section: {
    margin: theme.spacing(2, 0),
    "*:first-child": {
      marginTop: 0,
    },
  },
  input: {
    borderRadius: 20,
  },
  confirmation: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 95,
      paddingRight: 95,
    },
  },
  alertButtonPending: {
    backgroundColor: "#FFAB2E",
    "&:hover": {
      backgroundColor: "#E3931C",
    },
  },
}));

export default CompleteOrder;
