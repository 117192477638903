import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress color="primary"/>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default Loading;
