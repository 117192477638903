import React, { useState, useCallback, useEffect } from "react";
import {
  Drawer,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  BottomNavigation,
  BottomNavigationAction,
  Hidden,
} from "@material-ui/core";
import CustomIcon from "./CustomIcon";
import ROUTE_NAMES from "../config/routes";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const MenuOptions = [
  {
    name: "User information",
    shortName: "Info",
    icon: "User",
    path: ROUTE_NAMES.INFORMATION,
  },
  {
    name: "Stores",
    shortName: "Stores",
    icon: "Tag",
    path: ROUTE_NAMES.STORES,
  },
  {
    name: "Payment method",
    shortName: "Cards",
    icon: "CreditCard",
    path: ROUTE_NAMES.PAYMENT,
  },
  {
    name: "Settings",
    shortName: "Settings",
    icon: "Settings",
    path: ROUTE_NAMES.SETTINGS,
  },
];

const getPathIndex = (p) => MenuOptions.findIndex((o) => o.path === p);

const Sidebar = ({ pathname, history }) => {
  const classes = useStyles();
  const [value, setValue] = useState(getPathIndex(pathname));

  const handleTabChange = useCallback(
    (_, index) => {
      if (typeof index !== "number" || index < 0) return;
      const { path } = MenuOptions[index];
      setValue(index);
      history.push(path);
    },
    [history]
  );

  useEffect(() => {
    if (Boolean(pathname)) {
      const index = getPathIndex(pathname);
      if (index >= 0) {
        setValue(index);
      }
    }
  }, [pathname, handleTabChange]);

  return (
    <>
      <Hidden smDown>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <Toolbar className={classes.extraToolbar} />
          <div className={classes.drawerContainer}>
            <List>
              {MenuOptions.map((option) => (
                <ListItem
                  button
                  disableGutters
                  key={option.name}
                  component={Link}
                  to={option.path}
                  className={classes.item}
                >
                  <ListItemIcon>
                    <CustomIcon
                      color={pathname === option.path ? "primary" : "action"}
                      name={option.icon}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={pathname === option.path ? classes.active : ""}
                    primary={option.name}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <BottomNavigation
          className={classes.tabs}
          value={value}
          onChange={handleTabChange}
        >
          {MenuOptions.map((option) => (
            <BottomNavigationAction
              key={option.shortName}
              label={option.shortName}
              icon={<CustomIcon name={option.icon} />}
            />
          ))}
        </BottomNavigation>
      </Hidden>
    </>
  );
};

const drawerWidth = 300;
const customToolbarHeight = 76;

const useStyles = makeStyles((theme) => ({
  root: {},
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  item: {
    paddingLeft: theme.spacing(4),
  },
  active: {
    borderRightColor: theme.palette.primary.main,
    borderRightStyle: "solid",
    borderRightWidth: 3,
    color: theme.palette.primary.main,
    "& > span": {
      fontWeight: 600,
    },
  },
  extraToolbar: {
    height: customToolbarHeight,
  },
  tabs: {
    bottom: 0,
    boxShadow: "10px 11px 18px 6px #CCCCCC",
    left: 0,
    position: "fixed",
    width: "100%",
    zIndex: 10,
  },
}));

Sidebar.propTypes = {
  pathname: PropTypes.string,
  history: PropTypes.object,
};

export default Sidebar;
