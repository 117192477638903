import React from "react";
import { SvgIcon } from "@material-ui/core";
import * as Icon from "react-feather";
import PropTypes from 'prop-types'

const CustomIcon = ({ name, strokeWidth = 2, fill = "none", ...props }) => {
  const FeatherIcon = Icon[name];
  return (
    <SvgIcon {...props}>
      <FeatherIcon strokeWidth={strokeWidth} fill={fill} />
    </SvgIcon>
  );
};

CustomIcon.propTypes = {
  name: PropTypes.string.isRequired,
  fill: PropTypes.string,
};

export default CustomIcon;
