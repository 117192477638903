import React from "react";
import {
  Modal,
  Paper,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import CustomIcon from "./CustomIcon";
import PropTypes from "prop-types";

const ModalFormWrapper = ({ open, handleClose, title, children }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.root}
    >
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography
            className={classes.title}
            color="primary"
            variant="subtitle2"
            id="modal-title"
          >
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CustomIcon name="X" />
          </IconButton>
        </div>
        <div className={classes.content}>{children}</div>
      </Paper>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    left: "50%",
    height: "100%",
    padding: theme.spacing(2),
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("md")]: {
      borderRadius: 40,
      height: "unset",
      padding: theme.spacing(4, 6),
      width: 700,
    },
  },
  header: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
}));

ModalFormWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
};

export default ModalFormWrapper;
