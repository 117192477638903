/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useState } from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Hidden,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import BaseButton from "../components/BaseButton";
import * as yup from "yup";
import ROUTE_NAMES from "../config/routes";
import { useFirebaseContext } from "../contexts/Firebase";
import backgroundImage from "../assets/images/background.jpg";
import logoImage from "../assets/images/logo.png";

// Reset password validation schema
const validationSchema = yup.object({
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
});

const emailValidation = yup.string().email("Invalid email address").optional();

// Reset password page
const ResetPassword = () => {
  const classes = useStyles();
  const location = useLocation();
  const firebase = useFirebaseContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recoverResponse, setRecoverResponse] = useState(false);
  const [resetResponse, setResetResponse] = useState(false);
  const params = new URLSearchParams(location.search);
  const t = params.get("t");

  const handleOnEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e) => {
    setPassword(e.target.value);
    try {
      yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .max(60, "Password must be less than 60 characters")
        .validateSync(e.target.value);
      setError(null);
    } catch (error) {
      setError(error.message);
    }
  }, []);

  const handleConfirmPasswordChange = useCallback((e) => {
    setConfirmPassword(e.target.value);
  }, []);

  const handleSubmitEmail = useCallback(
    async (e) => {
      e.preventDefault();
      setError(null);
      try {
        emailValidation.validateSync(email);
        setLoading(true);
        await firebase.recoverAccount(email);
        setRecoverResponse(true);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error.message);
        setLoading(false);
      }
    },
    [firebase, email]
  );

  const handleSubmitReset = useCallback(async () => {
    try {
      validationSchema.validateSync(
        { password, confirmPassword },
        { abortEarly: true }
      );
      setError(null);
    } catch (error) {
      setError(error.message);
      return;
    }
    setLoading(true);
    try {
      await firebase.resetPassword(t, password);
      setResetResponse(true);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
    setLoading(false);
  }, [firebase, password, confirmPassword, t]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div className={classes.uggLogo}>
            <img src={`${logoImage}`} alt="Logo" className={classes.logoImg} />
            <div className={classes.uggText}>
              <Typography color="primary" variant="h2" align="left">
                UGG AUSTRALIA
              </Typography>
              <Hidden smDown>
                <Typography variant="body1" align="left">
                  Reset your password
                </Typography>
              </Hidden>
            </div>
          </div>
          <Hidden mdUp>
            <Typography
              className={classes.collapseText}
              variant="body1"
              align="left"
            >
              Reset your password
            </Typography>
          </Hidden>
          {!t && (
            <>
              {recoverResponse ? (
                <Typography
                  className={classes.feedback}
                  align="left"
                  variant="body1"
                >
                  Help is on the way. We sent you an email with the instructions
                  to reset your password.
                </Typography>
              ) : (
                <>
                  <form
                    id="email-form"
                    noValidate
                    autoComplete="none"
                    className={classes.formContainer}
                    onSubmit={handleSubmitEmail}
                  >
                    <TextField
                      type="email"
                      placeholder="Email"
                      fullWidth
                      value={email}
                      onChange={handleOnEmailChange}
                    />
                  </form>
                  <div className={classes.error}>
                    {error && (
                      <Typography
                        gutterBottom
                        color="secondary"
                        variant="caption"
                        align="left"
                      >
                        {error}
                      </Typography>
                    )}
                  </div>
                  <div className={classes.buttons}>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={classes.button}
                      component={Link}
                      to={ROUTE_NAMES.SIGN_IN}
                    >
                      Back to login
                    </Button>
                    <BaseButton
                      classes={{ root: classes.button, button: classes.button }}
                      color="primary"
                      size="large"
                      variant="contained"
                      disabled={loading}
                      loading={loading}
                      type="submit"
                      form="email-form"
                    >
                      Submit
                    </BaseButton>
                  </div>
                </>
              )}
            </>
          )}
          {t && (
            <>
              {resetResponse ? (
                <>
                  <Typography
                    className={classes.feedback}
                    align="left"
                    variant="body1"
                  >
                    Your password has been reseted, now signin with your email
                    and your new password.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={ROUTE_NAMES.SIGN_IN}
                  >
                    Go signin
                  </Button>
                </>
              ) : (
                <>
                  <form className={classes.formContainer}>
                    <TextField
                      type="password"
                      placeholder="******"
                      fullWidth
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <TextField
                      type="password"
                      fullWidth
                      placeholder="Repeat your password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                  </form>
                  <div className={classes.error}>
                    {error && (
                      <Typography
                        gutterBottom
                        color="secondary"
                        variant="caption"
                        align="left"
                      >
                        {error}
                      </Typography>
                    )}
                  </div>
                  <div className={classes.buttons}>
                    <BaseButton
                      className={classes.button}
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={handleSubmitReset}
                      disabled={loading}
                      loading={loading}
                    >
                      Submit
                    </BaseButton>
                  </div>
                </>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(15),
    },
  },
  card: {
    borderRadius: 10,
    margin: theme.spacing(0),
    maxWidth: 600,
    padding: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.up("xs")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },
  collapseText: {
    margin: theme.spacing(1),
  },
  formContainer: {
    "& > *": {
      display: "block",
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    "& > *:last-child": {
      marginBottom: 0,
    },
  },
  divider: {
    textAlign: "right",
    "& > p": {
      cursor: "pointer",
    },
  },
  uggLogo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
    },
  },
  uggText: {
    display: "flex",
    flexDirection: "column",
  },
  logoImg: {
    width: "90px",
    height: "90px",
    marginRight: theme.spacing(1),
  },
  checkbox: {
    paddingLeft: "0",
  },
  buttons: {
    display: "flex",
    marginTop: theme.spacing(2),
    "& > *:first-child": {
      marginRight: theme.spacing(1),
    },
    "& > *:last-child": {
      marginLeft: theme.spacing(1),
    },
  },
  button: {
    width: "100%",
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  error: {
    textAlign: "left",
    width: "100%",
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

export default ResetPassword;
