import React, { useEffect } from "react";
import { makeStyles, TextField, Grid } from "@material-ui/core";
import * as yup from "yup";
import useForm from "../hooks/form.hook";
import MaskedInput from "react-text-mask";
import getCardType from "../utils/card";
import MasterCardImage from "../assets/images/master-card.png";
import VisaImage from "../assets/images/visa.png";
import AmericanExpressImage from "../assets/images/american-express.png";
import PropTypes from 'prop-types';

const DEFAULT_STR_LENGTH = 255;
const EXP_DATE_REGEX = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
const CVV_DATE_REGEX = /^[0-9]{3,4}$/;
const CARD_NUMBER_REGEX = /([0-9- ]{18,19})/;

const cardValidationSchema = yup.object({
  number: yup
    .string()
    .trim()
    .matches(CARD_NUMBER_REGEX, "You must enter a valid credit card number")
    .required("Credit card number is required"),
  exp: yup
    .string()
    .matches(EXP_DATE_REGEX, "Expiration date must have MM/YY format")
    .required("Expiration date is required"),
  cvv: yup
    .string()
    .matches(CVV_DATE_REGEX, "CVV Code must have 3 or 4 digits")
    .required("CVV is required"),
  holderName: yup
    .string()
    .min(2, "Holder name must have at least 2 characters")
    .max(DEFAULT_STR_LENGTH, "Max length exceeded")
    .required("Holder name is required"),
  type: yup
    .string()
    .oneOf(
      ["visa", "mastercard", "amex"],
      "Only visa, mastercard or american express credit cards are allowed"
    )
    .required(),
});

const getCardImage = (type) => {
  switch (type) {
    case "visa":
      return VisaImage;
    case "mastercard":
      return MasterCardImage;
    case "amex":
      return AmericanExpressImage;
    default:
      return "https://www.wellsfargo.com/assets/images/css/template/placeholder_card_Desktop_1x.png";
  }
};

const ExpDateInputMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-1]/, /\d/, "/", /[2-3]/, /\d/]}
      placeholder="MM/YY"
      placeholderChar={"\u2000"}
    />
  );
};

const CardNumberInputMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholder="0000 0000 0000 0000"
      placeholderChar={"\u2000"}
    />
  );
};

const CardForm = ({
  children,
  initialValues = {},
  handleUpdateData,
  showCardTypeImage = false,
  collapseInputs = false,
}) => {
  const classes = useStyles();
  const { useInput, setValues, values, errors, isValid } = useForm(
    {
      number: initialValues.number || "",
      exp: initialValues.exp || "",
      cvv: initialValues.cvv || "",
      holderName: initialValues.holderName || "",
      type: initialValues.type || "",
    },
    cardValidationSchema
  );

  useEffect(() => {
    const type = getCardType(values.number);
    setValues((v) => ({ ...v, type }));
  }, [values.number, setValues]);

  // Exec parent callback when data is updated
  useEffect(() => {
    handleUpdateData && handleUpdateData({ errors, isValid, values });
  }, [handleUpdateData, errors, isValid, values]);

  const cardImage = getCardImage(values.type);

  return (
    <form noValidate autoComplete="on" className={classes.root}>
      {showCardTypeImage && (
        <div className={classes.type}>
          <img height="auto" width={243} src={cardImage} alt={values.type} />
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={11} sm={12} md={12}>
          <TextField
            label="Card number"
            variant="outlined"
            type="string"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputComponent: CardNumberInputMask }}
            {...useInput("number")}
          />
        </Grid>
        <Grid item xs={11} sm={12} md={collapseInputs ? 4 : 6}>
          <TextField
            label="Expiration date"
            variant="outlined"
            type="string"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputComponent: ExpDateInputMask }}
            {...useInput("exp")}
          />
        </Grid>
        <Grid item xs={11} sm={12} md={collapseInputs ? 4 : 6}>
          <TextField
            label="Code CVV"
            variant="outlined"
            type="number"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...useInput("cvv")}
          />
        </Grid>
        <Grid item xs={11} sm={12} md={collapseInputs ? 4 : 12}>
          <TextField
            label="Holder name"
            variant="outlined"
            type="text"
            fullWidth
            inputProps={{ className: classes.holderName }}
            InputLabelProps={{ shrink: true }}
            {...useInput("holderName")}
          />
        </Grid>
      </Grid>
      {children}
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  type: {
    margin: theme.spacing(2, 0),
    textAlign: "center",
    width: "100%",
  },
  holderName: {
    textTransform: "uppercase",
  },
}));

CardForm.propTypes = {
  children: PropTypes.element,
  initialValues: PropTypes.shape({
    number: PropTypes.string,
    exp: PropTypes.string,
    cvv: PropTypes.string,
    holderName: PropTypes.string,
    type: PropTypes.string,
  }),
  handleUpdateData: PropTypes.func,
  showCardTypeImage: PropTypes.bool,
  collapseInputs: PropTypes.bool,
};

export default CardForm;
