import React, { createContext, useContext } from "react";
import Firebase from "../services/firebase";

export const FirebaseContext = createContext(null);
export const useFirebaseContext = () => useContext(FirebaseContext)

const FirebaseProvider = ({ children }) => {
  const FirebaseInstance = new Firebase();
  return (
    <FirebaseContext.Provider value={FirebaseInstance}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
