import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  makeStyles,
  Button,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Fab,
  TablePagination,
  CircularProgress,
  TextField,
  MenuItem,
  Collapse,
  debounce,
  useTheme,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import ROUTE_NAMES from "../config/routes";
import { useFirebaseContext } from "../contexts/Firebase";
import { useUserContext } from "../contexts/User";
import CustomIcon from "../components/CustomIcon";
import currencyFormatter from "../utils/currency";
import clsx from "clsx";
import useForm from "../hooks/form.hook";
import * as yup from "yup";
import { useAlertContext } from "../contexts/Alert";

const ORDER_STATUSES = {
  ALL: "all",
  EDITING: "editing",
  PLACED: "placed",
  REVIEWING: "reviewing",
  COMPLETED: "completed",
  ON_PROGRESS: "on_progress",
};

const ITEMS_PER_PAGE = 5;
const INITIAL_PAGE = 0;
const INITIAL_SORT_PROPERTY = "createdAt";
const INITIAL_SORT_ORDER = "asc";

const headCells = [
  { id: "invoice", disablePadding: false, label: "Order #" },
  { id: "store", disablePadding: false, label: "Store name" },
  { id: "createdAt", disablePadding: false, label: "Date" },
  { id: "shipMethod", disablePadding: false, label: "Ship Method" },
  { id: "quantity", disablePadding: false, label: "# Items" },
  { id: "total", disablePadding: false, label: "Amount" },
  { id: "status", disablePadding: false, label: "Status" },
  { id: "action", disablePadding: true, label: "Action" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              classes={{ root: classes.headerCell }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const filterValidationSchema = yup.object({
  invoice: yup.string().optional(),
  storeName: yup.string().optional(),
  date: yup.string().optional(),
  shipMethod: yup.string().optional(),
  status: yup.string().optional(),
});

const getOrderCounts = (orders = []) => {
  return {
    total: orders.length,
    editing: orders.filter((o) => o.status === ORDER_STATUSES.EDITING).length,
    onProgress: orders.filter((o) => o.status === ORDER_STATUSES.ON_PROGRESS)
      .length,
    placed: orders.filter((o) => o.status === ORDER_STATUSES.PLACED).length,
    reviewing: orders.filter((o) => o.status === ORDER_STATUSES.REVIEWING)
      .length,
    completed: orders.filter((o) => o.status === ORDER_STATUSES.COMPLETED)
      .length,
  };
};

const INITIAL_FILTER_VALUES = {
  invoice: "",
  storeName: "",
  date: "",
  shipMethod: "",
  status: "",
};

const Orders = ({ handleChangeNavComponent, handleSetLayoutLoader }) => {
  const classes = useStyles();
  const history = useHistory();
  const firebase = useFirebaseContext();
  const { profile } = useUserContext();
  const [, alertDispatch] = useAlertContext();
  const [orderBy, setOrderBy] = useState(INITIAL_SORT_PROPERTY);
  const [order, setOrder] = useState(INITIAL_SORT_ORDER);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const theme = useTheme();
  const {
    useInput,
    values: filterValues,
    setValues: setFilterValues,
  } = useForm(INITIAL_FILTER_VALUES, filterValidationSchema);
  const mounted = useRef(true);

  const fetchOrders = useCallback(
    async (fv = {}) => {
      if (!profile) return;
      if (!mounted.current) return;
      setLoading(true);
      handleSetLayoutLoader(true);
      try {
        const orders = await firebase.queryUserOrders(fv);
        setOrders(orders);
      } catch (error) {
        console.log("Error fetching orders: ", error);
        setOrders([]);
      }
      handleSetLayoutLoader(false);
      setLoading(false);
    },
    [firebase, profile, handleSetLayoutLoader]
  );

  const handleRequestSort = useCallback(
    (_, property) => {
      if (!mounted.current) return;
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [orderBy, order]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnActionClick = useCallback(
    (id, status) => {
      if (status === "editing") {
        return history.push(ROUTE_NAMES.CREATE_ORDER);
      }
      return history.push(ROUTE_NAMES.COMPLETE.replace(":id", id));
    },
    [history]
  );

  const handleOnDownloadOrder = useCallback(
    async (id) => {
      setDownloadLoading(true);
      const orderWindow = window.open("");
      try {
        const orderContent = await firebase.downloadOrder(id);
        orderWindow.document.write(orderContent);
        orderWindow.document.close();
        orderWindow.focus();
        orderWindow.onload = () => {
          orderWindow.print();
        };
        orderWindow.onafterprint = (_) => {
          orderWindow.close();
        };
      } catch (error) {
        orderWindow.close();
        alertDispatch({
          type: "show",
          payload: {
            severity: "error",
            text: error.message || "Something went wrong.",
          },
        });
      }
      setDownloadLoading(false);
    },
    [firebase, alertDispatch]
  );

  const handleToggleFilters = useCallback(() => {
    setOpenFilters((o) => !o);
  }, []);

  const handleOnClearFilters = useCallback(() => {
    setFilterValues(INITIAL_FILTER_VALUES);
  }, [setFilterValues]);

  const debounceFetchOrders = useRef(debounce((fv) => fetchOrders(fv), 700))
    .current;

  useEffect(() => {
    debounceFetchOrders(filterValues);
  }, [filterValues, debounceFetchOrders]);

  useEffect(() => {
    handleChangeNavComponent("Your orders");
  }, [handleChangeNavComponent]);

  useEffect(() => {
    return () => (mounted.current = false);
  }, []);

  const counts = getOrderCounts(orders);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, orders.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Paper elevation={0} className={classes.statuses}>
          <div className={classes.status}>
            <div
              className={clsx([
                classes.cardIcon,
                classes.defaultBackgroundColor,
              ])}
            >
              <CustomIcon
                htmlColor={theme.palette.action.active}
                name="FileText"
              />
            </div>
            <div className={classes.cardText}>
              <Typography variant="h2">{counts.total}</Typography>
              <Typography variant="body1">Total orders</Typography>
            </div>
          </div>
          <div className={classes.status}>
            <div
              className={clsx([
                classes.cardIcon,
                classes.reviewingBackgroundColor,
              ])}
            >
              <CustomIcon htmlColor="#6B2E7D" name="Eye" />
            </div>
            <div className={classes.cardText}>
              <Typography variant="h2">{counts.reviewing}</Typography>
              <Typography variant="body1">Review</Typography>
            </div>
          </div>
          <div className={classes.status}>
            <div
              className={clsx([classes.cardIcon, classes.infoBackgroundColor])}
            >
              <CustomIcon htmlColor={theme.palette.info.main} name="Home" />
            </div>
            <div className={classes.cardText}>
              <Typography variant="h2">{counts.placed}</Typography>
              <Typography variant="body1">Placed</Typography>
            </div>
          </div>
          <div className={classes.status}>
            <div
              className={clsx([
                classes.cardIcon,
                classes.warningBackgroundColor,
              ])}
            >
              <CustomIcon
                htmlColor={theme.palette.warning.main}
                name="ChevronsRight"
              />
            </div>
            <div className={classes.cardText}>
              <Typography variant="h2">{counts.placed}</Typography>
              <Typography variant="body1">On progress</Typography>
            </div>
          </div>
          <div className={classes.status}>
            <div
              className={clsx([
                classes.cardIcon,
                classes.successBackgroundColor,
              ])}
            >
              <CustomIcon
                htmlColor={theme.palette.success.main}
                name="CheckCircle"
              />
            </div>
            <div className={classes.cardText}>
              <Typography variant="h2">{counts.completed}</Typography>
              <Typography variant="body1">Completed</Typography>
            </div>
          </div>
        </Paper>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={handleToggleFilters}
          endIcon={
            <CustomIcon name={openFilters ? "ChevronUp" : "ChevronDown"} />
          }
        >
          See filters
        </Button>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          component={Link}
          to={ROUTE_NAMES.CREATE_ORDER}
        >
          Create order
        </Button>
      </div>
      <Collapse in={openFilters}>
        <Paper elevation={0} className={classes.filters}>
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.filterInput}
            variant="outlined"
            label="Invoice #"
            type="text"
            {...useInput("invoice")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.filterInput}
            variant="outlined"
            label="Store"
            type="text"
            {...useInput("storeName")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.filterInput}
            variant="outlined"
            label="Date"
            type="text"
            {...useInput("date")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.filterInput}
            variant="outlined"
            label="Ship method"
            type="text"
            {...useInput("shipMethod")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.filterInput}
            variant="outlined"
            label="Status"
            type="text"
            select
            {...useInput("status")}
          >
            {Object.values(ORDER_STATUSES).map((option) => (
              <MenuItem className="capitalize" key={option} value={option}>
                <Typography className="capitalize" variant="inherit">
                  {option}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
          <Button
            className={classes.filterInput}
            color="primary"
            disabled={loading || filterValues === INITIAL_FILTER_VALUES}
            onClick={handleOnClearFilters}
            size="small"
            variant="outlined"
          >
            Clear
          </Button>
        </Paper>
      </Collapse>
      <Paper elevation={0} className={classes.content}>
        <TableContainer>
          <Table size="medium" aria-label="Orders table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody classes={{ root: classes.tableBody }}>
              {stableSort(orders, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow tabIndex={-1} key={row.id}>
                      <TableCell
                        className={classes.tableCell}
                        id={labelId}
                        scope="row"
                      >
                        {row.invoice || "-"}
                      </TableCell>
                      <TableCell
                        className={clsx([classes.tableCell, "uppercase"])}
                      >
                        {row.shipping?.name || "-"}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.createdAt}
                      </TableCell>
                      <TableCell className={clsx([classes.tableCell, "capitalize"])}>
                        {row.shipping?.method || "-"}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.items.reduce((t, n) => t + n.quantity, 0)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {currencyFormatter.format(row.total)}
                      </TableCell>
                      <TableCell
                        className={clsx([classes.tableCell, "capitalize"])}
                      >
                        <div
                          className={clsx([
                            "capitalize",
                            classes.badge,
                            {
                              [classes.warningBackgroundColor]:
                                row.status === ORDER_STATUSES.ON_PROGRESS,
                              [classes.warningColor]:
                                row.status === ORDER_STATUSES.ON_PROGRESS,
                              [classes.successBackgroundColor]:
                                row.status === ORDER_STATUSES.COMPLETED,
                              [classes.successColor]:
                                row.status === ORDER_STATUSES.COMPLETED,
                              [classes.dangerBackgroundColor]:
                                row.status === ORDER_STATUSES.EDITING,
                              [classes.dangerColor]:
                                row.status === ORDER_STATUSES.EDITING,
                              [classes.infoBackgroundColor]:
                                row.status === ORDER_STATUSES.PLACED,
                              [classes.infoColor]:
                                row.status === ORDER_STATUSES.PLACED,
                              [classes.reviewingBackgroundColor]:
                                row.status === ORDER_STATUSES.REVIEWING,
                              [classes.reviewingColor]:
                                row.status === ORDER_STATUSES.REVIEWING,
                            },
                          ])}
                        >
                          {row.status}
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell} padding="none">
                        <div className={classes.fabs}>
                          <Fab
                            color="default"
                            className={classes.actionButton}
                            size="small"
                            onClick={handleOnActionClick.bind(
                              null,
                              row.id,
                              row.status
                            )}
                          >
                            <CustomIcon
                              name={
                                row.status === "editing"
                                  ? "ArrowRightCircle"
                                  : "Eye"
                              }
                              fontSize="small"
                            />
                          </Fab>
                          <div
                            className={clsx([
                              classes.loaderWrapper,
                              classes.actionButton,
                            ])}
                          >
                            <Fab
                              size="small"
                              onClick={handleOnDownloadOrder.bind(null, row.id)}
                            >
                              <CustomIcon name="Printer" fontSize="small" />
                            </Fab>
                            {downloadLoading && (
                              <CircularProgress
                                color="primary"
                                size={40}
                                className={classes.fabProgress}
                              />
                            )}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell className={classes.tableCell} colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Showing"
        />
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    position: "relative",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 4),
    },
  },
  content: {
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 20,
  },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
    },
  },
  statuses: {
    alignItems: "center",
    borderColor: theme.palette.divider,
    borderRadius: 20,
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
      justifyContent: "space-around",
      marginBottom: 0,
      marginRight: theme.spacing(2),
      padding: theme.spacing(2, 0),
    },
  },
  status: {
    alignItems: "center",
    display: "flex",
    flexBasis: 150,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      minWidth: 160,
      marginBottom: 0,
      marginRight: theme.spacing(3),
    },
  },
  cardIcon: {
    alignItems: "center",
    borderRadius: "50%",
    display: "flex",
    height: 52,
    justifyContent: "center",
    width: 52,
  },
  infoBackgroundColor: {
    backgroundColor: theme.palette.info.light,
  },
  defaultBackgroundColor: {
    backgroundColor: "#F4F4F4",
  },
  dangerBackgroundColor: {
    backgroundColor: theme.palette.error.light,
  },
  successBackgroundColor: {
    backgroundColor: theme.palette.success.light,
  },
  warningBackgroundColor: {
    backgroundColor: theme.palette.warning.light,
  },
  reviewingBackgroundColor: {
    backgroundColor: "#F3ECF8",
  },
  reviewingColor: {
    color: "#6B2E7D",
  },
  infoColor: {
    color: theme.palette.info.main,
  },
  defaultColor: {
    color: "#505050",
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
  dangerColor: {
    color: theme.palette.error.main,
  },
  successColor: {
    color: theme.palette.success.main,
  },
  cardText: {
    marginLeft: theme.spacing(2),
    verticalAlign: "middle",
  },
  button: {
    flexGrow: 1,
    "&:first-child": {
      marginRight: theme.spacing(1),
    },
    "&:last-child": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      flexGrow: 0,
      margin: theme.spacing(0, 1),
      minWidth: 150,
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  headerCell: {
    fontWeight: "bold",
  },
  tableCell: {
    fontWeight: "normal",
  },
  pagination: {
    "& > div > p": {
      fontWeight: "normal",
    },
  },
  fabs: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
  },
  actionButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  loaderWrapper: {
    display: "inline",
    position: "relative",
  },
  fabProgress: {
    position: "absolute",
    top: -10,
    left: 0,
    zIndex: 1,
  },
  filters: {
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 20,
    display: "flex",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
      flexWrap: "nowrap",
    },
  },
  filterInput: {
    margin: theme.spacing(1, 0),
    flexBasis: 100,
    "&:last-child": {
      width: 80,
      marginRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: 170,
      marginBottom: 0,
      marginTop: 0,
      marginRight: theme.spacing(3),
    },
  },
  badge: {
    borderRadius: 40,
    minWidth: 100,
    padding: theme.spacing(1, 0.5),
    textAlign: "center",
    width: "fit-content",
  },
}));

export default Orders;
