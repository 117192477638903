import { handleHttpErrors } from "../utils/error";

export const getLocations = async (search, token) => {
  const url = new URL(
    `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/freight/locations`
  );
  if (search) {
    url.search = new URLSearchParams({ q: search });
  }
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  }).then(handleHttpErrors);
  return res.json();
};

export const calculateFreight = async (
  { orderID, to, postcodeTo, from, postcodeFrom },
  token
) => {
  const url = new URL(
    `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/freight/${orderID}/calculate`
  );
  const res = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      to,
      postcodeTo,
      from,
      postcodeFrom,
    }),
  }).then(handleHttpErrors);
  return res.json();
};
