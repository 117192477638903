import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import firebaseConfig from "../config/firebase";
import groupBy from "lodash/groupBy";
import { handleHttpErrors } from "../utils/error";
import { isDevelopment } from "../utils/environment";

export const FIRESTORE_PERMISSION_DENIED_ERROR_CODE = "permission-denied";

const addExtraFee = (extras = [], itemExtras = [], sizeId, colorId) => {
  return itemExtras.reduce((total, next) => {
    const extra = extras.find((e) => e.id === next.id);
    if (!extra) return total;
    let value = total;
    if (extra.sizes && extra.sizes.find((s) => s.id === sizeId))
      value = value + extra.value;
    if (extra.colors && extra.colors.find((c) => c.id === colorId))
      value = value + extra.value;
    return value;
  }, 0);
};

class Firebase {
  constructor() {
    this.app = app.initializeApp(firebaseConfig);
    this.db = app.firestore();
    this.storage = app.storage();
    this.auth = app.auth();
    if (isDevelopment()) {
      this.auth.useEmulator(process.env.REACT_APP_FIREBASE_AUTH_EMULATOR);
      this.db.settings({
        host: process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR,
        ssl: false,
      });
    }
  }

  // *** Counters API ***
  counters = () => this.db.collection("counters");

  counter = (id) => this.db.collection("counters").doc(id);

  // *** Items API ***
  items = () => this.db.collection("items");

  item = (id) => this.db.collection("items").doc(id);

  // *** Categories API ***

  categories = () => this.db.collection("categories");

  category = (id) => this.db.collection("categories").doc(id);

  // *** Types API ***
  types = () => this.db.collection("types");

  type = (id) => this.db.collection("types").doc(id);

  // *** Colors API ***
  colors = () => this.db.collection("colors");

  color = (id) => this.db.collection("colors").doc(id);

  // *** Sizes API ***
  sizes = () => this.db.collection("sizes");

  size = (id) => this.db.collection("sizes").doc(id);

  // *** Orders API ***
  orders = () => this.db.collection("orders");

  order = (id) => this.db.collection("orders").doc(id);

  queryUserOrders = async ({
    invoice = "",
    storeName = "",
    date = "",
    shipMethod = "",
    status = "",
  }) => {
    try {
      const url = new URL(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/orders`
      );
      url.search = new URLSearchParams({
        i: invoice,
        sn: storeName,
        d: date,
        sm: shipMethod,
        s: status,
      });
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: await this.getAuthToken(),
        },
      });
      if (!res.ok) return null;
      return res.json();
    } catch (error) {
      console.log("Error querying orders: ", error);
      return [];
    }
  };

  downloadOrder = async (id) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/orders/${id}/download`,
        {
          method: "GET",
          headers: {
            Authorization: await this.getAuthToken(),
          },
        }
      ).then(handleHttpErrors);
      return res.text();
    } catch (error) {
      console.log("Error generating invoice : ", error);
      throw new Error(
        error.message
          ? error.message
          : "Unexpected error, please try again or contact an administrator"
      );
    }
  };

  confirmOrder = async (id, data) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/orders/${id}/confirm`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            authorization: await this.getAuthToken(),
          },
        }
      ).then(handleHttpErrors);
      return res.text();
    } catch (error) {
      console.log("Error on confirm order : ", error);
      throw new Error(
        error.message
          ? error.message
          : "Unexpected error, please try again or contact an administrator"
      );
    }
  };

  // *** Stores API ***
  stores = () => this.db.collection("stores");

  storesByCustomer = (uid) => {
    const ownerRef = this.user(uid);
    return this.db
      .collection("stores")
      .where("deleted", "==", null)
      .where("user", "==", ownerRef);
  };

  store = (id) => this.db.collection("stores").doc(id);

  // *** Extras API ***

  extras = () => this.db.collection("extras");

  // *** Users API ***
  user = (uid) => this.db.collection("users").doc(uid);

  currentUser = () => this.user(this.auth.currentUser.uid);

  toggleFavorite = async (uid, itemId) => {
    const itemRef = this.item(itemId);
    const userDoc = await this.user(uid).get();
    const { favorites = [] } = userDoc.data();
    const favoriteExists = favorites.findIndex((f) => f.id === itemId) > -1;
    const newFavorites = favoriteExists
      ? app.firestore.FieldValue.arrayRemove(itemRef)
      : app.firestore.FieldValue.arrayUnion(itemRef);
    return this.user(uid).update({
      favorites: newFavorites,
    });
  };

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = async (email, password) => {
    const { user } = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    const { uid, displayName } = user;
    return this.user(user.uid).set({
      uid,
      email,
      displayName,
      ...this.getFirebaseTimestamps(),
    });
  };

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => res.user.getIdTokenResult())
      .then(async ({ claims }) => {
        if (claims.role === "admin") {
          await this.doSignOut();
          throw new Error("You are accessing to the wrong platform");
        }
        return true;
      });

  doSignOut = () => this.auth.signOut();

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  validateAccount = async (token) => {
    try {
      const url = new URL(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/auth/validate`
      );
      url.search = new URLSearchParams({ t: token });
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: await this.getAuthToken(),
        },
      }).then(handleHttpErrors);
      this.auth.currentUser.reload();
      return res.text();
    } catch (error) {
      console.log("Error validating account: ", error);
      throw new Error(
        error.message
          ? error.message
          : "Unexpected error, please try again or contact an administrator"
      );
    }
  };

  recoverAccount = async (email) => {
    try {
      const url = new URL(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/auth/recover`
      );
      await fetch(url, {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(handleHttpErrors);
      return true;
    } catch (error) {
      console.log(error);
      throw new Error(
        error.message
          ? error.message
          : "Unexpected error, please try again or contact an administrator"
      );
    }
  };

  resetPassword = async (token, password) => {
    try {
      const url = new URL(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/auth/reset`
      );
      await fetch(url, {
        method: "POST",
        body: JSON.stringify({ t: token, p: password }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(handleHttpErrors);
      return true;
    } catch (error) {
      console.log(error);
      throw new Error(
        error.message
          ? error.message
          : "Unexpected error, please try again or contact an administrator"
      );
    }
  };

  resendValidationEmail = async () => {
    try {
      const url = new URL(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/auth/resend`
      );
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: await this.getAuthToken(),
        },
      }).then(handleHttpErrors);
      return res.text();
    } catch (error) {
      console.log("Error re sending validation email: ", error);
      throw new Error(
        error.message
          ? error.message
          : "Unexpected error, please try again or contact an administrator"
      );
    }
  };

  updatePassword = async (currentPassword, newPassword) => {
    const user = this.auth.currentUser;
    const credential = app.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    // Prompt the user to re-provide their sign-in credentials
    await user.reauthenticateWithCredential(credential);
    return this.auth.currentUser.updatePassword(newPassword);
  };

  getAuthToken = async () => {
    const token = await this.auth.currentUser.getIdToken();
    return `Bearer ${token}`;
  };

  // *** Storage API ***
  uploadFile = async (ref, file) => {
    return this.storage
      .ref(ref)
      .put(file)
      .then((c) => c.ref.getDownloadURL());
  };

  // *** Transformers ***
  getOrderItemSortKey = (...keys) => keys.join("_");

  parseOrderItemList = async (items) => {
    const { docs: extrasDocs } = await this.extras().get();
    const extras = extrasDocs.map((e) => e.data());
    let newItems = await Promise.all(
      items.map(async (i) => {
        const itemData = (await i.id.get()).data();
        return {
          ...i,
          name: itemData.name,
          price:
            itemData.price +
            addExtraFee(extras, itemData.extras, i.size.id, i.color.id),
          id: i.id.id,
          color: i.color.id,
          size: i.size.id,
        };
      })
    );
    newItems = groupBy(newItems, "id");
    newItems = Object.values(newItems).map((list) => {
      const { name, id } = list[0];
      const quantity = list.reduce((total, next) => total + next.quantity, 0);
      const total = list.reduce(
        (total, next) => total + next.price * next.quantity,
        0
      );
      return { name, quantity, total, list, id };
    });
    return newItems;
  };

  calculateOrderPriceValues = async (id) => {
    const url = new URL(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/orders/${id}/calculate`
    );
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: await this.getAuthToken(),
      },
    }).then(handleHttpErrors);
    return res.json();
  };

  getFirebaseTimestamps = () => ({
    createdAt: app.firestore.FieldValue.serverTimestamp(),
    updatedAt: app.firestore.FieldValue.serverTimestamp(),
  });

  getFirestoreArrayUtils = () => ({
    union: app.firestore.FieldValue.arrayUnion,
    remove: app.firestore.FieldValue.arrayRemove,
  });
}

export default Firebase;
