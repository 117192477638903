import { useCallback, useEffect, useState } from 'react';

/**
 *
 * @param {Object}  options Form input hook options
 * @param {string}  options.name  The field name assigned to this input
 * @param {Object}  options.validation  This param has to be a yup validation function
 * @param {Object}  options.values  Current values of the whole form
 * @param {Function} options.setValues Callback function to set the form values
 * @param {Object}  options.defaultInvalidAttr  Error handling options for activate or deactivate show errors
 * @param {Function} handleError options.handleError The error callback function
 */
export function useFormInput({
  name,
  values: formData,
  setValues: setFormData,
  defaultInvalidAttr,
  error
}) {
  const formValue = formData[name] || '';

  const [value, setValue] = useState(formValue);
  const [isTouched, setIsTouched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // watch for external parent data changes
  useEffect(() => {
    if (value !== formValue) {
      setValue(formValue);
      setIsTouched(false);
    }
  }, [formValue, value, setValue, setIsFocused, setIsTouched]);

  // rewrite self and parent's value
  const handleChange = useCallback(
    ({ target }) => {
      const { value: tValue, checked, type } = target;
      const newValue = type === 'checkbox' ? checked : tValue;
      const data = { ...formData, [name]: newValue };
      setValue(newValue);
      setFormData(data);
    },
    [setValue, formData, setFormData, name]
  );

  // handle input focus attr
  const handleFocus = useCallback(() => {
    setIsTouched(true);
    setIsFocused(true);
  }, [setIsTouched, setIsFocused]);

  // handle input blur attr
  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);

  const showError = Boolean(error) && isTouched && !isFocused;
  const invalidAttr = showError ? defaultInvalidAttr : null;

  return {
    value,
    name,
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    helperText: showError ? error : null,
    ...invalidAttr
  };
}

/**
 * @param {Object} defaultValues Default form values
 * @param {Object} validationSchema Mandatory yup validation schema
 * @param {Object} invalidAttr Show error options
 */
function useForm(
  defaultValues,
  validationSchema,
  invalidAttr = { error: true }
) {
  const [values, setValues] = useState(defaultValues);
  const [mounted, setMounted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (validationSchema) {
      setMounted(true);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false
        });
        setFormErrors({});
        setIsValid(true);
      } catch (error) {
        const parsedErorrs = error.inner.reduce(
          (total, next) => ({
            ...total,
            [next.path]: next.message
          }),
          {}
        );
        setFormErrors(parsedErorrs);
        setIsValid(false);
      }
    }
  }, [validationSchema, values]);

  /**
   * @param {string} name Field input name
   */
  const useInput = name => {
    return useFormInput({
      name,
      values,
      setValues,
      defaultInvalidAttr: invalidAttr,
      error: formErrors[name]
    });
  };

  return {
    values,
    setValues,
    useInput,
    errors: formErrors,
    isValid: mounted && isValid
  };
}

export default useForm;