const getCardType = (number) => {
  if (!number) return "";
  number = number.trim();
  // visa
  let re = new RegExp("^4");
  if (number.match(re) != null) return "visa";

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  re = new RegExp("^5[1-5]")
  if (number.match(re) != null) return "mastercard";

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) return "amex";

  return "";
};

export default getCardType;
