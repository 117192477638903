import React, { useState, useCallback } from "react";
import { makeStyles, Paper, Typography, Fab } from "@material-ui/core";
import CustomIcon from "./CustomIcon";
import StoreHeaderBackground from "../assets/images/store.png";
import AlertDialog from "./AlertDialog";
import PropTypes from "prop-types";
import clsx from "clsx";

const StoreCard = ({
  name,
  owner,
  address,
  phone,
  email,
  onEdit,
  onDelete,
  selectable,
  hideInfo,
  selected,
}) => {
  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);

  const handleOpenAlert = useCallback(() => {
    setOpenAlert(true);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setOpenAlert(false);
  }, []);

  return (
    <Paper
      elevation={0}
      className={clsx([
        classes.root,
        hideInfo ? classes.center : "",
        selected ? classes.selected : "",
        selectable && !selected ? classes.selectable : "",
      ])}
    >
      <AlertDialog
        open={openAlert}
        handleClose={handleCloseAlert}
        message="Are you sure to delete this store?"
        handleConfirm={onDelete}
        showCancelButton
      />
      {selected && (
        <CustomIcon
          className={classes.checkmark}
          color="primary"
          name="CheckCircle"
          fontSize="large"
        />
      )}
      <div className={classes.header}>
        {!selectable && (
          <div className={classes.actions}>
            <Fab size="small" className={classes.button} onClick={onEdit}>
              <CustomIcon color="action" name="Edit2" />
            </Fab>
            <Fab
              size="small"
              className={classes.button}
              onClick={handleOpenAlert}
            >
              <CustomIcon color="secondary" name="Trash" />
            </Fab>
          </div>
        )}
        <Typography className={classes.title} variant="subtitle2">
          {name}
        </Typography>
        <Typography className={classes.title} variant="body1">
          {owner}
        </Typography>
      </div>
      {!hideInfo && (
        <div className={classes.content}>
          <div className={classes.item}>
            <CustomIcon name="MapPin" />
            <Typography variant="body1">{address}</Typography>
          </div>
          <div className={classes.item}>
            <CustomIcon name="Phone" />
            <Typography variant="body1">{phone}</Typography>
          </div>
          <div className={classes.item}>
            <CustomIcon name="Mail" />
            <Typography variant="body1">{email}</Typography>
          </div>
        </div>
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    borderColor: theme.palette.divider,
    borderRadius: 20,
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
      maxWidth: 280,
    },
  },
  header: {
    backgroundImage: `url(${StoreHeaderBackground})`,
    backgroundSize: "40%",
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    padding: theme.spacing(4, 2),
  },
  actions: {
    padding: theme.spacing(1, 0),
  },
  button: {
    margin: theme.spacing(0, 0.5),
  },
  title: {
    lineHeight: 1.2,
    textTransform: "capitalize",
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  item: {
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    padding: theme.spacing(2),
    "&:first-child": {
      borderTop: `1px solid ${theme.palette.divider}`,
      flex: 1,
    },
    "&:last-child": {
      borderBottom: "none",
    },
    "& > p": {
      marginLeft: theme.spacing(2),
    },
  },
  center: {
    justifyContent: "center",
  },
  selectable: {
    "&:hover": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
  },
  selected: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    position: "relative",
    overflow: "visible",
    "& h6": {
      color: theme.palette.primary.main,
    },
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  checkmark: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 2,
  },
}));

StoreCard.propTypes = {
  name: PropTypes.string.isRequired,
  owner: PropTypes.string,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
};

export default StoreCard;
