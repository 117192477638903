import React from "react";
import { makeStyles } from "@material-ui/core";

const VoidLayout = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      minHeight: "100vh",
    },
  },
}));

export default VoidLayout;
