export const ROUTE_NAMES = {
  HOME: "/",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  VALIDATE_ACCOUNT: "/signup/validate",
  RESET_PASSWORD: "/signup/reset",
  NOT_FOUND: "/404",
  ABOUT: "/about",
  CONTACT: "/contact",
  PROFILE: "/profile",
  INFORMATION: "/profile/information",
  UPDATE_PASSWORD: "/profile/information/password",
  STORES: "/profile/stores",
  PAYMENT: "/profile/payment",
  SETTINGS: "/profile/settings",
  NOTIFICATIONS: "/profile/notifications",
  ORDERS: "/orders",
  CREATE_ORDER: "/orders/create",
  COMPLETE: "/orders/:id/complete",
  TERMS_AND_CONDITIONS: "/terms-and-conditions"
};

export default ROUTE_NAMES;
