import React from "react";
import {
  makeStyles,
  Modal,
  Paper,
  Typography,
  Button,
  IconButton,
  withStyles,
} from "@material-ui/core";
import CustomIcon from "./CustomIcon";
import clsx from "clsx";

const styles = {
  root: {
    backgroundColor: "rgba(255, 90, 90, 0.09)",
    borderRadius: "50%",
    color: "#FF8277",
    fontSize: 24,
    height: 70,
    margin: "0 auto",
    padding: 15,
    width: 70,
    "& > span": {
      verticalAlign: "middle",
    },
  },
};

const AlertSymbol = ({ classes }) => (
  <div className={classes.root}>
    <span>!</span>
  </div>
);

const AlertIcon = withStyles(styles)(AlertSymbol);

const AlertDialog = ({
  open,
  handleClose,
  title = "Alert",
  message,
  handleConfirm,
  color = "primary",
  classes: parentClasses = { root: null, content: null, button: null },
  icon,
  showCancelButton = false,
  confirmButtonText = "Continue",
  ModalProps = {},
  showCloseAlertButton = true,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.root}
      {...ModalProps}
    >
      <Paper className={clsx([classes.paper, parentClasses.root])}>
        <div className={classes.header}>
          {icon ? (
            icon
          ) : (
            <div className={classes.iconContainer}>
              <AlertIcon />
            </div>
          )}
          {showCloseAlertButton && (
            <IconButton className={classes.close} onClick={handleClose}>
              <CustomIcon name="X" />
            </IconButton>
          )}
        </div>
        <div className={clsx([classes.content, parentClasses.content])}>
          <Typography gutterBottom variant="subtitle2">
            {title}
          </Typography>
          {typeof message === "string" ? (
            <Typography variant="body1">{message}</Typography>
          ) : (
            message
          )}
        </div>
        <div className={classes.buttonContainer}>
          {showCancelButton && (
            <Button
              color={color}
              variant="outlined"
              onClick={handleClose}
              className={classes.button}
            >
              Cancel
            </Button>
          )}
          <Button
            color={color}
            variant="contained"
            onClick={handleConfirm}
            className={clsx([classes.button, parentClasses.button])}
          >
            {confirmButtonText}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    borderRadius: 20,
    minHeight: 300,
    left: "50%",
    overflow: "hidden",
    position: "absolute",
    textAlign: "center",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.up("sm")]: {
      borderRadius: 40,
      height: "unset",
      textAlign: "left",
      width: 400,
    },
  },
  close: {
    position: "absolute",
    right: 15,
    top: 15,
  },
  content: {
    margin: theme.spacing(2, 0),
    textAlign: "center",
  },
  iconContainer: {
    backgroundColor: "#F7F7F7",
    padding: theme.spacing(4),
    textAlign: "center",
  },
  buttonContainer: {
    margin: theme.spacing(3, 0),
    textAlign: "center",
    "& > *:first-child": {
      marginRight: theme.spacing(1),
    },
    "& > *:last-child": {
      marginLeft: theme.spacing(1),
    },
  },
  button: {
    minWidth: 130,
  },
  header: {
    position: "relative",
  },
}));

export default AlertDialog;
