export const PaymentMethods = {
  PREMIUM: "premium",
  CREDIT_CARD: "Credit card",
  DIRECT_DEPOSIT: "Direct deposit",
};

export const ShippingMethods = {
  PICKUP: "pickup",
  REGIONAL: "regional",
  CAPITAL: "capital city",
};
