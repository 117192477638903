import React from "react";
import clsx from "clsx";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const CustomCard = ({ icon, title, children, color }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.root}>
      <div
        className={clsx([
          classes.header,
          {
            [classes[color]]: true,
          },
        ])}
      >
        {icon}
      </div>
      <div className={classes.content}>
        <Typography gutterBottom variant="subtitle2">
          {title}
        </Typography>
        {children}
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.divider,
    borderRadius: 28,
    borderStyle: "solid",
    borderWidth: 1,
    maxWidth: 345,
    overflow: "hidden",
  },
  header: {
    padding: theme.spacing(3),
    textAlign: "center",
    position: "relative",
    height: 120,
    "& > *": {
      position: "absolute",
      top: "calc(50% - 30px)",
      left: "calc(50% - 35px)",
      zIndex: 10,
    },
    "&::after": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: "50%",
      borderTopRightRadius: "50%",
      height: 30,
      width: "100%",
      zIndex: 2,
    },
  },
  content: {
    padding: theme.spacing(3),
  },
  logo: {
    margin: theme.spacing(4, 0),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  error: {
    backgroundColor: theme.palette.error.light,
  },
  info: {
    backgroundColor: theme.palette.info.light,
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
}));

CustomCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  color: PropTypes.oneOf(["success", "info", "error", "warning"]).isRequired,
};

export default CustomCard;
