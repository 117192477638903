import React, { useCallback, useState, cloneElement } from "react";
import NavBar from "../components/NavBar";
import { makeStyles, Toolbar } from "@material-ui/core";

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const [navChildren, setNavChildren] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleChangeNavComponent = useCallback((component) => {
    setNavChildren(component);
  }, []);

  const handleSetLayoutLoader = useCallback((load) => {
    setLoading(load);
  }, []);

  const childrenWithNavCallbacks = cloneElement(children, {
    handleChangeNavComponent,
    handleSetLayoutLoader,
  });

  return (
    <>
      <NavBar navChildren={navChildren} loading={loading} />
      <main id="main-layout-container" className={classes.content}>
        <Toolbar />
        <Toolbar className={classes.extraToolbar} />
        {childrenWithNavCallbacks}
      </main>
    </>
  );
};

const customToolbarHeight = 76;

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    minHeight: "100%",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
      maxHeight: "unset",
    },
  },
  extraToolbar: {
    minHeight: customToolbarHeight,
  },
}));

export default MainLayout;
