import React from "react";
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  withStyles,
  StepConnector,
} from "@material-ui/core";
import PropTypes from 'prop-types'

import clsx from "clsx";
import CustomIcon from "./CustomIcon";

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <CustomIcon name="Check" className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const steps = ["Shipping", "Payment", "Confirm"];

const OrderStepper = ({ step = 0 }) => {
  const classes = useStyles();

  return (
    <>
      <Stepper
        className={classes.root}
        activeStep={step}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
  },
}));

OrderStepper.propTypes = {
  step: PropTypes.number,
};

export default OrderStepper;
