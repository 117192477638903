const terms = `
# Terms and Conditions

Thank you for visiting the Ugg Australia® Pty Ltd website and reviewing our privacy policy. Ugg Australia Pty Ltd is committed to safeguarding the privacy of everyone who shops or interacts with us online and in person.

Ugg Australia® Pty Ltd is also bound by the national privacy principles set out in the privacy act 1988 (cth). Ugg Australia® Pty Ltd will only collect, use or disclose personal information about you in accordance with that act. Please read the following privacy policy to understand how Ugg Australia® Pty Ltd deals with the personal information that it collects about you.

Your access and use of this website is conditional on your acceptance of this privacy policy. Your continued use of this site indicates your consent to the collection and use by us of any personal information you provide whilst using the site, in the manner set out below. You should also read our terms and conditions for use of website which also govern your access and use of this website.

We reserve the right to change this privacy policy at any time. If the privacy policy is changed, details of the changes will be posted on this website but we will not separately notify you of these changes.

Personal information
When we talk about personal information we are referring to the definition in the privacy act, which states that:

‘personal information’ means information or an opinion (including information or an opinion forming part of a database), whether true or not, and whether recorded in a material form or not, about a person whose identity is apparent or can be reasonably ascertained, from the information or opinion.

What personal information does Ugg Australia® Pty Ltd collect?

Important Note: For privacy & security reasons, Ugg Australia® does not store credit card information.

Ugg Australia pty ltd collects personal information from a wide variety of sources including this website. We only collect information that is necessary for one or more or our functions or activities, and we only keep it for as long as we may require it for a legitimate business purpose.

If you do nothing during your visit but browse through the website, read the pages or download information, our website will automatically record some information about your visit to the site. The type of information that will be collected includes the type of web browser and operating system that you are using, the date and time of visit to the site and the web pages that you accessed. If you linked to our site from another site then that information will also be recorded. We may use this information for statistical analysis but the system does not record personal information about individuals unless you volunteer it.

Among the personal information that Ugg Australia® Pty Ltd commonly collects is the following:

Name, address and telephone numbers;
Transaction records, including those relating to your purchases or other business dealings with us;
Records of any queries or complaints that you may make;
E-mail address where the individual has:
Selected to register for information service via the website or has contacted the ugg Australia pty ltd consumer enquiries team by email;
Otherwise contacted us by email; or
Otherwise been requested to provide an email address;
Provided an email address through promotions, competitions or customer surveys;
Recordings of some telephone calls to consumer enquiries; and
Any other information that an individual has provided to us as a result of any dealing, transaction or negotiation with us.
When we collect personal information from an individual, we ensure that we do so in a fair manner. We will also use our best efforts to let the individual know:

How to contact us
Why we are collecting the information;
The organisation or types of organisation to which we usually disclose that kind of information;
If we are required by law to collect that information; and
The consequences for you if the information is not provided
Use of personal information
The national privacy principles require that, other than for certain specified exceptions, we only use your information for the purpose for which it was collected or a purpose related to that purpose. Some of the purposes for which we commonly use your information are as follows:

To assist in providing our products and services to you;
To assist with any calls that you make to ugg australia pty ltd consumer enquiries;
To communicate promotional offers and special events; and for our internal administrative, marketing, planning, product development and research requirements.
However, ugg australia pty ltd may also use information for any other purpose for which the information was provided and for purposes reasonably related to that purpose.

Disclosure of your information to third parties

Ugg australia pty ltd recognises that some of the information you choose to supply may be personal information. Ugg australia pty ltd will not disclose your personal information to a third party except:

For the purposes for which we have been advised we are collecting it, and for related purposes that could reasonably be expected in the circumstances;
With your consent; or
As part of an asset sale of its business or as specified by law.
Ugg australia pty ltd will not sell personal information under any circumstances or share it with anyone for marketing purposes unless we have been advised of this on collection or otherwise obtained your consent.

Website privacy statement
In relation to this website, Ugg Australia® Pty Ltd collects, uses and discloses information in the following ways:

Ip address

Ugg Australia® Pty Ltd’s web servers (and feedback forms on the web) gather your IP address to help diagnose problems with our service, to administer our website, and to gather broad user information (for example, which areas of our site are visited most frequently). Such user information is gathered in aggregate only and cannot be traced to an individual user.

Cookies
A cookie is a small data file. Ugg Australia® Pty Ltd does not use permanent cookies that are stored on your hard drive after you have visited our website. However, we do use temporary cookies which are only stored on your hard drive for the duration of a single browser session. These temporary cookies allow our servers to keep a record of where users have visited on our website. Users who do not want to receive cookies can configure their web browsers to refuse them, however doing so may prevent some part of our website from working.

Information submitted by email or online forms
Users may choose to supply Ugg Australia® Pty Ltd with information either via email links from the website or by completing and submitting one or more of the forms, which are available on the website.

Consumer enquiry centre - privacy statement
Calls to Ugg Australia® Pty Ltd consumer enquiries (calls) may be recorded for quality, training and verification purposes. Even if your call is not recorded, consumer enquiries may collect information that you provide during the calls. In relation to information provided during calls, Ugg Australia® Pty Ltd collects, uses and discloses information in the following ways:

Customer service - Ugg Australia® Pty Ltd will use the information you provide during calls to assist in answering your query or addressing your complaint. This may include using information you provide to contact you following the call in response to your query or complaint.
Quality - if your query or complaint relates to the quality of products or services provided by an Ugg Australia® Pty Ltd staff member/department, then the information may be used to address that issue with the staff member/department in question.
Generally, it will not be necessary to identify you when the complaint is raised by the staff member/department. If it is necessary, we will seek your consent first.

Training - ugg Australia pty ltd may use the recording of the telephone conversation for internal training purposes.
Verification - if you have a complaint in relation to the quality of Ugg Australia® Pty Ltd products, we may retain the recording of the telephone conversation for verification purposes. Ugg Australia® Pty Ltd may also disclose the recording to third parties, including its professional advisors, in order to address the complaint or any action you may take in relation to the complaint.
Destruction of your information
We will take reasonable steps to destroy or de-identify any personal information about you which we hold when it is no longer needed by us.

Security
Ugg Australia® Pty Ltd will take reasonable steps to protect the personal information we collect from you from misuse, loss or unauthorised access, modification or disclosure.

Accessing and checking the personal information we hold about you
We will take reasonable steps to ensure that the personal information we collect from or about you is accurate, complete and up to date whenever it is used, collected or disclosed. You are entitled to access your information if you wish and subject to any legal restrictions, we would be happy to advise you what personal information we hold about you if you request this.

There may be some cost to you to cover the cost of retrieving and professing the information.

We rely on the accuracy of the information you provide us. If you think that we may hold information about you that is incorrect in any way, please contact us and we will correct any errors or inaccuracies where required.

If at any time you wish to know what information we are holding about you, you are welcome to request your details by contacting us.

Contacting us
If you have any questions about this privacy policy or these privacy statements, or your dealings with us, please go to our contact page.

Miscellaneous
This privacy policy represents our policy as of 23 September 2010. We may change this policy from time to time. Although we intend to observe this policy at all times, it is not legally binding on Ugg Australia® Pty Ltd in any way.

From time to time, we may regard it as necessary or desirable to act outside the policy and Ugg Australia® Pty Ltd may do so, subject only to any statutory rights you have under the privacy act or other applicable legislation.

In this policy, references to Ugg Australia® Pty Ltd are to Ugg Australia® Pty Ltd (ACN 138 925 596), which has issued this policy. This policy is also adopted by all Ugg Australia® Pty Ltd subsidiaries and related bodies corporate which are subject to the act.
`

export default terms;
