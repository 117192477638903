import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Hidden,
  Paper,
  IconButton,
  Fab,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import OrderItem from "./OrderItem";
import currencyFormatter from "../utils/currency";
import clsx from "clsx";
import CustomIcon from "./CustomIcon";
import PropTypes from "prop-types";

const OrderSummary = ({
  items = [],
  colors,
  sizes,
  total,
  subtotal,
  tax,
  freight = 0,
  charge = 0,
  onRemoveItem,
  onRemoveItemBySortKey,
  onDownloadOrder,
  handleClose,
  loading,
  downloadLoading,
  edit,
  onEdit,
  children,
  showEditButton = true,
}) => {
  const classes = useStyles();

  const tableHeaders = (
    <>
      <Grid container justify="flex-start">
        <Grid item xs={6} sm={6} md={6}>
          <Typography variant="body2">Description</Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          <Typography variant="body2">Qty</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Typography variant="body2">Total</Typography>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Paper square elevation={0} className={classes.root}>
      <div className={classes.wrapper}>
        <div
          className={clsx([
            classes.loaderWrapper,
            { [classes.rightSpace]: !edit && showEditButton },
          ])}
        >
          <Fab
            color="default"
            size="small"
            aria-label="download"
            onClick={onDownloadOrder}
            disabled={downloadLoading}
          >
            <CustomIcon name="Printer" />
          </Fab>
          {downloadLoading && (
            <CircularProgress
              color="primary"
              size={49}
              className={classes.fabProgress}
            />
          )}
        </div>
      </div>
      {!edit && showEditButton && (
        <Fab
          className={classes.edit}
          color="default"
          size="small"
          aria-label="edit"
          onClick={onEdit}
        >
          <CustomIcon name="Edit2" />
        </Fab>
      )}
      <Hidden mdUp>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
        >
          <CustomIcon name="X" />
        </IconButton>
      </Hidden>
      <div className={classes.header}>
        <Typography
          className={classes.titleOrder}
          color="primary"
          variant="subtitle2"
        >
          Current order
        </Typography>
        {tableHeaders}
      </div>
      <div className={classes.content}>
        {items.map((i) => (
          <OrderItem
            edit={edit}
            key={i.id}
            id={i.id}
            name={i.name}
            quantity={i.quantity}
            total={i.total}
            list={i.list}
            colors={colors}
            sizes={sizes}
            onRemoveItem={onRemoveItem}
            onRemoveItemBySortKey={onRemoveItemBySortKey}
            loading={loading}
          />
        ))}
      </div>
      <div className={classes.orderFooter}>
        <div className={classes.section}>
          <div className={classes.row}>
            <Typography variant="body1">Subtotal</Typography>
            <Typography align="right" variant="body1">
              {currencyFormatter.format(subtotal)}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="body1">Freight</Typography>
            <Typography align="right" variant="body1">
              {currencyFormatter.format(freight)}
            </Typography>
          </div>
          {Boolean(charge) && (
            <div className={classes.row}>
              <Typography variant="body1">
                Service charge
                <Tooltip disableFocusListener title="Less than 10 items">
                  <IconButton className={classes.infoContainer} size="small">
                    <CustomIcon
                      color="primary"
                      className={classes.info}
                      name="Info"
                      aria-label="Service charge"
                    />
                  </IconButton>
                </Tooltip>
              </Typography>

              <Typography align="right" variant="body1">
                {currencyFormatter.format(charge)}
              </Typography>
            </div>
          )}
          <div className={classes.row}>
            <Typography variant="body1">Tax (10%)</Typography>
            <Typography align="right" variant="body1">
              {currencyFormatter.format(tax)}
            </Typography>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.row}>
            <Typography color="primary" variant="subtitle2">
              Total
            </Typography>
            <Typography color="primary" align="right" variant="subtitle1">
              {currencyFormatter.format(total)}
            </Typography>
          </div>
          {children}
        </div>
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minHeight: "100%",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      minHeight: "unset",
      overflowY: "scroll",
    },
  },
  header: { padding: theme.spacing(1, 4) },
  content: {
    flex: 1,
  },
  section: {
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: theme.palette.divider,
    padding: theme.spacing(2, 3, 2, 4),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 6, 2, 4),
    },
  },
  row: {
    alignItems: "center",
    display: "flex",
    margin: theme.spacing(1, 0),
    "& > *": {
      flex: 1,
    },
  },
  closeButton: {
    color: theme.palette.common.black,
    position: "absolute",
    top: 0,
    right: 0,
    "z-index": 10,
  },
  wrapper: {
    position: "absolute",
    right: theme.spacing(5),
    top: theme.spacing(0),
    zIndex: 20,
    [theme.breakpoints.up("md")]: {
      right: 0,
      top: theme.spacing(1),
    },
  },
  loaderWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  fabProgress: {
    position: "absolute",
    top: -5,
    left: -5,
    zIndex: 1,
  },
  edit: {
    position: "absolute",
    right: theme.spacing(7),
    top: theme.spacing(1),
    zIndex: 20,
    [theme.breakpoints.up("md")]: {
      right: theme.spacing(1),
      top: theme.spacing(2),
    },
  },
  rightSpace: {
    right: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      right: theme.spacing(6),
    },
  },
  titleOrder: {
    marginBottom: theme.spacing(2),
  },
  infoContainer: {
    marginLeft: theme.spacing(0.5),
  },
  info: {
    fontSize: 16,
    verticalAlign: "middle",
  },
}));

OrderSummary.propTypes = {
  items: PropTypes.array,
  colors: PropTypes.array,
  sizes: PropTypes.array,
  total: PropTypes.number,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  onRemoveItem: PropTypes.func,
  onRemoveItemBySortKey: PropTypes.func,
  onDownloadOrder: PropTypes.func,
  handleClose: PropTypes.func,
  loading: PropTypes.bool,
  downloadLoading: PropTypes.bool,
  edit: PropTypes.bool,
  onEdit: PropTypes.func,
  children: PropTypes.element,
  showEditButton: PropTypes.bool,
};

export default OrderSummary;
