import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

/**
 * 
 * @param {Object} props Component props
 * @param {Object} props.classes Child element default classNames
 * @param {boolean} props.loading Loading flag
 * @param {Object} props.SpinnerProps Props applied to Spinner component
 */
const BaseButton = ({
  children,
  classes: inheritClasses = { root: null, spinner: null, button: null },
  loading,
  SpinnerProps = {},
  ...props
}) => {
  const classes = useStyles();
  return (
    <div className={clsx([classes.wrapper, inheritClasses.root])}>
      <Button className={inheritClasses.button} {...props}>
        {children}
        {loading && (
          <CircularProgress
            size={24}
            color="inherit"
            {...SpinnerProps}
            className={clsx([classes.buttonProgress, inheritClasses.spinner])}
          />
        )}
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

export default BaseButton;
