/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useState } from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Hidden,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import BaseButton from "../components/BaseButton";
import useForm from "../hooks/form.hook";
import * as yup from "yup";
import ROUTE_NAMES from "../config/routes";
import { useFirebaseContext } from "../contexts/Firebase";
import backgroundImage from "../assets/images/background.jpg";
import logoImage from "../assets/images/logo.png";
import clsx from "clsx";

// Signin form validation schema
const validationSchema = yup.object({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

// Signin page
const Signin = () => {
  const classes = useStyles();
  const firebase = useFirebaseContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { isValid, values, useInput, setValues } = useForm(
    { email: "", password: "" },
    validationSchema
  );

  // Submit signin form
  const handleSubmit = useCallback(async () => {
    if (isValid) {
      setLoading(true);
      try {
        await firebase.doSignInWithEmailAndPassword(
          values.email,
          values.password
        );
      } catch (error) {
        if (
          error.code === "auth/user-not-found" ||
          error.code === "auth/wrong-password"
        ) {
          setError("Invalid email or password");
        } else {
          console.log(error);
          const message = error.message || "Unexpected error occurred";
          setError(message);
        }
        setValues((values) => ({ ...values, password: "" }));
        setLoading(false);
      }
    }
  }, [firebase, values, isValid, setValues]);

  // Add handle key listener to submit form on press enter key
  const handleKeyPress = useCallback(
    (e) => {
      // ENTER KEY CODE = 13
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div className={classes.uggLogo}>
            <img src={logoImage} alt="Logo" className={classes.logoImg} />
            <div className={classes.uggText}>
              <Typography color="primary" variant="h2" align="left">
                UGG AUSTRALIA
              </Typography>
              <Hidden smDown>
                <Typography variant="body1" align="left">
                  Welcome back! Please login to your account
                </Typography>
              </Hidden>
            </div>
          </div>
          <Hidden mdUp>
            <Typography
              className={classes.collapseText}
              variant="body1"
              align="left"
            >
              Welcome back! Please login to your account
            </Typography>
          </Hidden>
          <form className={classes.formContainer}>
            <TextField
              type="email"
              placeholder="Your email"
              InputProps={{
                autoComplete: "username",
              }}
              fullWidth
              {...useInput("email")}
            />
            <TextField
              type="password"
              placeholder="******"
              InputProps={{
                autoComplete: "password",
              }}
              fullWidth
              {...useInput("password")}
              onKeyDown={handleKeyPress}
            />
          </form>
          <div className={classes.divider}>
            <Typography
              component={Link}
              to={ROUTE_NAMES.RESET_PASSWORD}
              variant="body1"
              color="textPrimary"
              className={classes.link}
            >
              Forgot password
            </Typography>
          </div>
          <div className={clsx([classes.uggLogo, classes.actions])}>
            <BaseButton
              color="primary"
              size="large"
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
              classes={{ root: classes.button, button: classes.button }}
            >
              Sign in
            </BaseButton>
            <div className={classes.spacer}></div>
            <Button
              className={classes.button}
              component={Link}
              to={ROUTE_NAMES.SIGN_UP}
              color="primary"
              size="large"
              variant="outlined"
            >
              Register
            </Button>
          </div>
          {error && (
            <Typography gutterBottom color="secondary" variant="caption">
              {error}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(15),
    },
  },
  card: {
    borderRadius: 10,
    margin: theme.spacing(0),
    maxWidth: 600,
    padding: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.up("xs")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },
  collapseText: {
    margin: theme.spacing(1),
  },
  formContainer: {
    "& > *": {
      display: "block",
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    "& > *:last-child": {
      marginBottom: 0,
    },
  },
  divider: {
    textAlign: "right",
    "& > p": {
      cursor: "pointer",
    },
  },
  uggLogo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
    },
  },
  uggText: {
    display: "flex",
    flexDirection: "column",
  },
  logoImg: {
    width: "90px",
    height: "90px",
    marginRight: theme.spacing(1),
  },
  checkbox: {
    paddingLeft: "0",
  },
  button: {
    width: "100%",
  },
  spacer: {
    width: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
  },
}));

export default Signin;
