import { debounce } from "@material-ui/core";
import { useCallback, useRef, useState } from "react";
import { getLocations } from "../services/freight";

const useAddresses = (firebase) => {
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  const fetchLocations = useCallback(
    async (search) => {
      setLoading(true);
      const token = await firebase.getAuthToken();
      const results = await getLocations(search, token);
      setLocations(results);
      setLoading(false);
    },
    [firebase]
  );

  const debounceFetchLocations = useRef(debounce((s) => fetchLocations(s), 700))
    .current;

  return {
    loading,
    locations,
    debounceFetchLocations,
  };
};

export default useAddresses;
