import React from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { ReactComponent as PremiumIcon } from "../assets/icons/premium.svg";
import clsx from "clsx";
import PropTypes from "prop-types";

const PremiumAccountCard = ({
  badge,
  classes: parentClasses = { root: null },
}) => {
  const classes = useStyles();

  const cardNodeElement = (
    <>
      <div className={classes.header}>
        <PremiumIcon height={60} width={60} className={classes.icon} />
        <Typography variant="subtitle2">Premium account</Typography>
      </div>
      <Typography variant="body1">
        Enjoy this benefit paying 30 days later.
      </Typography>
    </>
  );

  const badgeNodeElement = (
    <>
      <div className={classes.info}>
        <Typography variant="subtitle2">
          Premium account is now available.
        </Typography>
        <Typography variant="body1">
          Create you order and enjoy this benefit paying 30 days later.
        </Typography>
      </div>
      <PremiumIcon className={classes.badgeIcon} />
    </>
  );

  return (
    <Paper
      elevation={0}
      className={clsx([
        badge ? classes.badge : classes.root,
        parentClasses.root,
      ])}
    >
      {!badge && cardNodeElement}
      {badge && badgeNodeElement}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#EBEBEB",
    borderRadius: 10,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("sm")]: {
      maxHeight: 205,
      maxWidth: 340,
    },
    [theme.breakpoints.up("md")]: {
      width: 260,
      height: 146,
    },
  },
  badge: {
    alignItems: "center",
    display: "flex",
    borderRadius: 20,
    padding: theme.spacing(3, 4),
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: "' '",
      height: "100%",
      borderLeftColor: "#FFAB2E",
      borderLeftStyle: "solid",
      borderLeftWidth: 6,
      position: "absolute",
      left: 0,
      top: 0,
    },
  },
  info: {
    flexGrow: 1,
    marginRight: theme.spacing(3),
  },
  header: {
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  badgeIcon: {
    height: 100,
    width: 100,
    [theme.breakpoints.up("sm")]: {
      height: 60,
      width: 60,
    },
  },
}));

PremiumAccountCard.propTypes = {
  badge: PropTypes.bool,
  classes: PropTypes.shape({ root: PropTypes.string }),
};

export default PremiumAccountCard;
