import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import BarlowRegularTtf from "../assets/fonts/Barlow-Regular.ttf";

const barlow = {
  fontFamily: "Barlow",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Barlow'),
    local('Barlow-Regular'),
    url(${BarlowRegularTtf}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

let theme = createMuiTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 320,
      sm: 540,
      md: 1100,
      lg: 1366,
      xl: 1920,
    },
  },
  palette: {
    background: {
      default: "#FBFBFB",
    },
    primary: {
      main: "#002d6a",
      light: "#405599",
      dark: "#00023f",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ee3524",
      light: "#ff6d4f",
      dark: "#b30000",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#424242",
      secondary: "#222222",
    },
    warning: {
      main: "#FFAB2E",
      light: "#FFF5E8",
    },
    info: {
      main: "#1976D2",
      light: "#E5F0FF",
    },
    success: {
      main: "#1AA000",
      light: "#ECF8EC",
    },
    error: {
      main: "#EE3424",
      light: "#FFEFEF",
    },
  },
  typography: {
    fontFamily: "'Barlow', sans-serif",
    h1: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
    subtitle1: {
      fontSize: "1.25rem",
    },
    subtitle2: {
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "1rem", // 14 px
    },
    body2: {
      fontSize: "1rem", // 14 px
      fontWeight: "bold",
    },
    caption: {
      fontSize: "0.875rem", // 14 px
    },
    button: {
      fontSize: "1rem",
    },
  },
  overrides: {
    MuiIconButton: {
      root: { color: "#555555" },
    },
    MuiButton: {
      root: { borderRadius: 50, textTransform: "none" },
      contained: { boxShadow: "none" },
      outlined: { borderWidth: 2 },
      outlinedPrimary: {
        borderWidth: 2,
        borderColor: "#002d6a",
        "&:hover": {
          borderWidth: 2,
          borderColor: "#002d6a",
        },
      },
    },
    MuiFab: {
      root: { boxShadow: "none", backgroundColor: "#F4F4F4", color: "#555555" },
    },
    MuiBottomNavigationAction: {
      root: { color: "#555555" },
    },
    MuiOutlinedInput: {
      root: { borderRadius: 50 },
      input: { paddingTop: 12, paddingBottom: 12 },
      inputMarginDense: { paddingBottom: 5.5, paddingTop: 5.5 },
    },
    MuiInputBase: {
      input: {
        /* Chrome, Safari, Edge, Opera */
        "&::-webkit-outer-spin-button": {},
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        /* Firefox */
        "-moz-appearance": "textfield",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": [barlow],
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
