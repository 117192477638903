import React from "react";
import { useUserContext } from "../contexts/User";
import { Route, Redirect } from "react-router-dom";
import ROUTE_NAMES from "../config/routes";
import PropTypes from "prop-types";

/**
 *
 * Custom route guard to prevent not logged users access
 */
const PrivateRoute = ({ children, verifyStrict = false, ...rest }) => {
  const { profile, data = {} } = useUserContext();

  if (!profile) {
    return (
      <Redirect
        to={{ pathname: ROUTE_NAMES.SIGN_IN, state: { from: rest.location } }}
      />
    );
  }
  // Temporally removed email verified restriction due to the SendGrid API blocks.
  // if (verifyStrict && !profile.emailVerified) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: ROUTE_NAMES.PROFILE,
  //         state: {
  //           from: rest.location,
  //           alert: "email-verification",
  //         },
  //       }}
  //     />
  //   );
  // }
  if (verifyStrict && (!data || !data.validated)) {
    return (
      <Redirect
        to={{
          pathname: ROUTE_NAMES.PROFILE,
          state: {
            from: rest.location,
            alert: "complete-profile",
          },
        }}
      />
    );
  }
  const childrenWithProps = React.cloneElement(children, rest);
  return <Route {...rest}>{childrenWithProps}</Route>;
};

PrivateRoute.propTypes = {
  children: PropTypes.element,
  verifyStrict: PropTypes.bool,
};

export default PrivateRoute;
