import React, { useCallback, useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import CustomCard from "../components/CustomCard";
import useForm from "../hooks/form.hook";
import * as yup from "yup";
import BaseButton from "../components/BaseButton";
import { ReactComponent as LockIcon } from "../assets/icons/lock.svg";
import { useFirebaseContext } from "../contexts/Firebase";
import { useAlertContext } from "../contexts/Alert";
import { useHistory } from "react-router-dom";
import ROUTE_NAMES from "../config/routes";

const validationSchema = yup.object({
  password: yup.string().required("Current password is required"),
  newPassword: yup
    .string()
    .min(6, "New password must be at least 6 characters")
    .max(60, "New password must be less than 60 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
});

const UpdatePassword = () => {
  const classes = useStyles();

  const history = useHistory();
  const firebase = useFirebaseContext();
  const [, alertDispatch] = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isValid, values, useInput } = useForm(
    {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema
  );

  const handleUpdatePassword = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isValid) return;
      setLoading(true);
      setError(null);
      try {
        await firebase.updatePassword(values.password, values.newPassword);
        alertDispatch({
          type: "show",
          payload: {
            severity: "success",
            text: "Password updated successfully",
          },
        });
        return history.push(ROUTE_NAMES.PROFILE);
      } catch (error) {
        if (error.code === "auth/wrong-password") {
          setError("Invalid current password")
        } else {
          console.log(error);
          setError(error.message);
        }
      }
      setLoading(false);
    },
    [alertDispatch, firebase, history, isValid, values]
  );

  return (
    <div className={classes.root}>
      <CustomCard title="Change password" color="info" icon={<LockIcon />}>
        <>
          <form
            id="password-form"
            className={classes.form}
            noValidate
            onSubmit={handleUpdatePassword}
          >
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="Current password"
              InputLabelProps={{ shrink: true }}
              {...useInput("password")}
            />
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="New password"
              InputLabelProps={{ shrink: true }}
              {...useInput("newPassword")}
            />
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="Confirm password"
              InputLabelProps={{ shrink: true }}
              {...useInput("confirmPassword")}
            />
          </form>
          <div className={classes.error}>
            {error && (
              <Typography
                gutterBottom
                color="secondary"
                variant="caption"
                align="left"
              >
                {error}
              </Typography>
            )}
          </div>
          <BaseButton
            className={classes.button}
            color="primary"
            fullWidth
            size="large"
            variant="contained"
            form="password-form"
            type="submit"
            loading={loading}
            disabled={loading || !isValid}
          >
            Change
          </BaseButton>
        </>
      </CustomCard>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    minHeight: "calc(100% - 64px - 76px)",
    padding: theme.spacing(4, 0),
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    "& > *": {
      margin: theme.spacing(2, 0),
    },
    "& > *:last-child": {
      marginBottom: 0,
    },
  },
  button: {
    marginTop: theme.spacing(2),
  },
  error: {
    marginTop: theme.spacing(2),
    textAlign: "left",
    width: "100%",
  },
}));

export default UpdatePassword;
