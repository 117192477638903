import React, { useState, useCallback, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Button,
  Hidden,
  Fab,
} from "@material-ui/core";
import { useFirebaseContext } from "../contexts/Firebase";
import { useAlertContext } from "../contexts/Alert";
import { useUserContext } from "../contexts/User";
import StoreCard from "../components/StoreCard";
import ModalFormWrapper from "../components/ModalFormWrapper";
import StoreForm from "../components/StoreForm";
import BaseButton from "../components/BaseButton";
import { ReactComponent as EmptyStoresIcon } from "../assets/icons/store-empty.svg";
import CustomIcon from "../components/CustomIcon";
import clsx from "clsx";
import useAddresses from "../hooks/addresses.hook";

const Stores = ({
  handleSetLayoutLoader,
  selectable,
  selected,
  onSelect,
  onPickupSelect,
  classes: parentClasses = { fab: null, selectableTitleBox: null },
  selectableDisabled = false,
}) => {
  const classes = useStyles();
  const firebase = useFirebaseContext();
  const [, alertDispatch] = useAlertContext();
  const { profile, data: userData } = useUserContext();
  const [form, setForm] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState(null);
  const [editing, setEditing] = useState({});
  const {
    debounceFetchLocations: fetchLocations,
    loading: loadingLocs,
    locations,
  } = useAddresses(firebase);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setEditing({});
  }, []);

  const checkOutdatedAddresses = useCallback(
    (list = []) => {
      const hasOutdatedStores = list.filter((s) => !s.postcodeID).length > 0;
      if (hasOutdatedStores) {
        alertDispatch({
          type: "show",
          payload: {
            severity: "warning",
            text: "Please update your store address",
          },
        });
      }
    },
    [alertDispatch]
  );

  const fetchStores = useCallback(async () => {
    setLoading(true);
    handleSetLayoutLoader(true);
    const { docs = [], empty } = await firebase
      .storesByCustomer(profile.uid)
      .get();
    const list = docs.map((i) => i.data());
    setStores(list);
    checkOutdatedAddresses(list);
    if (selectable && !empty && docs.length === 1) {
      const sel = docs[0].data();
      onSelect(sel);
    }
    handleSetLayoutLoader(false);
    setLoading(false);
  }, [
    firebase,
    profile,
    handleSetLayoutLoader,
    selectable,
    onSelect,
    checkOutdatedAddresses,
  ]);

  const createStore = useCallback(
    async (storeData) => {
      const doc = firebase.stores().doc();
      const user = firebase.user(userData.uid);
      await doc.set({
        ...storeData,
        name: storeData.name.toLowerCase(),
        email: storeData.email.toLowerCase(),
        deleted: null,
        id: doc.id,
        user,
        ownerName: userData.name || "",
        ...firebase.getFirebaseTimestamps(),
      });
    },
    [firebase, userData]
  );

  const updateStore = useCallback(
    (id, storeData) => {
      const { updatedAt } = firebase.getFirebaseTimestamps();
      return firebase.store(id).update({
        ...storeData,
        name: storeData.name.toLowerCase(),
        email: storeData.email.toLowerCase(),
        updatedAt,
      });
    },
    [firebase]
  );

  const handleSubmit = useCallback(async () => {
    const { isValid, values } = form;
    if (!isValid) return;
    setLoading(true);
    if (editing.id) {
      await updateStore(editing.id, values);
    } else {
      await createStore(values);
    }
    alertDispatch({
      type: "show",
      payload: { text: editing.id ? "Store updated" : "Store added" },
    });
    setLoading(false);
    setOpenModal(false);
    setEditing({});
    fetchStores();
  }, [alertDispatch, editing, form, fetchStores, createStore, updateStore]);

  const handleUpdateInformation = useCallback((formData) => {
    setForm(formData);
  }, []);

  const handleOnEdit = useCallback((store) => {
    setEditing(store);
    setOpenModal(true);
  }, []);

  const handleOnDelete = useCallback(
    async (id) => {
      handleSetLayoutLoader(true);
      await firebase.store(id).delete();
      await fetchStores();
      alertDispatch({ type: "show", payload: { text: "Store removed" } });
    },
    [firebase, alertDispatch, handleSetLayoutLoader, fetchStores]
  );

  useEffect(() => {
    fetchStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyState = (
    <div className={classes.empty}>
      <EmptyStoresIcon />
      <div>
        <Typography variant="subtitle1">
          You have not added stores yet
        </Typography>
        <Typography variant="body1">Start adding stores</Typography>
      </div>
    </div>
  );

  return (
    <div className={clsx([classes.root, { [classes.wrapped]: selectable }])}>
      <ModalFormWrapper
        title={editing.id ? `Update ${editing.name}` : "Add new store"}
        open={openModal}
        handleClose={handleCloseModal}
      >
        <StoreForm
          initialValues={editing}
          handleUpdateData={handleUpdateInformation}
          loadingLocations={loadingLocs}
          onSearchLocation={fetchLocations}
          locations={locations}
        >
          <div className={classes.submitContainer}>
            <BaseButton
              disabled={!form.isValid || loading}
              className={classes.submit}
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleSubmit}
              loading={loading}
            >
              Save
            </BaseButton>
          </div>
        </StoreForm>
      </ModalFormWrapper>
      <Paper
        elevation={0}
        className={clsx([classes.paper, { [classes.wrapped]: selectable }])}
      >
        <Hidden smDown>
          <Button
            className={classes.add}
            color="primary"
            variant="contained"
            aria-label="create new store"
            onClick={handleOpenModal}
            disabled={loading}
          >
            Create new store
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Fab
            className={clsx([classes.add, parentClasses.fab])}
            color="primary"
            aria-label="add new store"
            onClick={handleOpenModal}
            disabled={loading}
            size="small"
          >
            <CustomIcon fontSize="small" name="Plus" />
          </Fab>
        </Hidden>
        {selectable ? (
          <div className={parentClasses.selectableTitleBox}>
            <Typography variant="subtitle2">
              Which store do you want to send the order?
            </Typography>
            <Typography variant="body1">My stores</Typography>
          </div>
        ) : (
          <Typography color="primary" variant="subtitle2">
            My stores
          </Typography>
        )}
        <div className={classes.grid}>
          {stores &&
            stores.length === 0 &&
            !loading &&
            !selectable &&
            emptyState}
          {stores &&
            stores.map((store, i) => (
              <div
                onClick={
                  selectable
                    ? onSelect.bind(null, { ...store, isStore: true })
                    : null
                }
                className={clsx([
                  classes.item,
                  { [classes.selectable]: selectable && !selectableDisabled },
                ])}
                key={store.id + i}
              >
                <StoreCard
                  selectable={selectable}
                  address={`${store.address}, ${store.city}, ${store.zip}`}
                  email={store.email}
                  phone={store.phone}
                  owner={store.ownerName}
                  name={store.name}
                  selected={selected === store.id}
                  onEdit={handleOnEdit.bind(null, store)}
                  onDelete={handleOnDelete.bind(null, store.id)}
                />
              </div>
            ))}
          {selectable && (
            <>
              {userData && (
                <div
                  onClick={
                    selectable
                      ? onSelect.bind(null, { ...userData, isStore: false })
                      : null
                  }
                  className={clsx([
                    classes.item,
                    { [classes.selectable]: selectable && !selectableDisabled },
                  ])}
                >
                  <StoreCard
                    selectable
                    address={`${userData.address}, ${userData.city}, ${userData.zip}`}
                    email={userData.email}
                    phone={userData.phone}
                    owner={userData.name}
                    name={userData.name}
                    selected={selected === "customer"}
                  />
                </div>
              )}
              <div
                onClick={selectable ? onPickupSelect : null}
                className={clsx([
                  classes.item,
                  { [classes.selectable]: selectable && !selectableDisabled },
                ])}
              >
                <StoreCard
                  selectable
                  hideInfo
                  address="31-35 Gilberston Road. Laverton North. 3026"
                  email="sales@uggaustralia.com.au"
                  phone="+61403594204"
                  owner="UGG Australia"
                  name="Pick up"
                  selected={selected === "pickup"}
                />
              </div>
            </>
          )}
        </div>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 6),
    },
  },
  paper: {
    borderRadius: 20,
    height: "100%",
    position: "relative",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
  grid: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      alignItems: "stretch",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },
  item: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      flexBasis: 270,
      margin: theme.spacing(1, 2, 1, 0),
    },
  },
  submitContainer: {
    textAlign: "center",
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },
  submit: {
    minWidth: 150,
  },
  add: {
    position: "absolute",
    right: 10,
    top: 10,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 3),
      right: 30,
      top: 5,
    },
  },
  empty: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    width: "100%",
  },
  selectable: {
    cursor: "pointer",
    borderColor: "transparent",
    borderRadius: 20,
    borderStyle: "solid",
    borderWidth: 2,
  },
  wrapped: {
    padding: 0,
  },
}));

export default Stores;
