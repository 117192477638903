import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import ROUTE_NAMES from "../config/routes";
import NonAuthRoute from "./NonAuthRoute";
import PrivateRoute from "./PrivateRoute";
import CreateOrder from "../pages/CreateOrder";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import CompleteOrder from "../pages/CompleteOrder";
import VoidLayout from "../layouts/Void";
import MainLayout from "../layouts/Main";
import MenuLayout from "../layouts/Menu";
import Orders from "../pages/Orders";
import ValidateAccount from "../pages/ValidateAccount";
import ResetPassword from "../pages/ResetPassword";
import TermsAndConditions from "../pages/TermsAndConditions";

export default () => {
  return (
    <Switch>
      <PrivateRoute exact path={ROUTE_NAMES.HOME}>
        <Redirect to={ROUTE_NAMES.PROFILE} />
      </PrivateRoute>
      <NonAuthRoute exact path={ROUTE_NAMES.SIGN_IN}>
        <VoidLayout>
          <SignIn />
        </VoidLayout>
      </NonAuthRoute>
      <NonAuthRoute exact path={ROUTE_NAMES.SIGN_UP}>
        <VoidLayout>
          <SignUp />
        </VoidLayout>
      </NonAuthRoute>
      <NonAuthRoute exact path={ROUTE_NAMES.RESET_PASSWORD}>
        <VoidLayout>
          <ResetPassword />
        </VoidLayout>
      </NonAuthRoute>
      <PrivateRoute exact path={ROUTE_NAMES.VALIDATE_ACCOUNT}>
        <MainLayout>
          <ValidateAccount />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute path={ROUTE_NAMES.PROFILE}>
        <MenuLayout />
      </PrivateRoute>
      <PrivateRoute verifyStrict exact path={ROUTE_NAMES.ORDERS}>
        <MainLayout>
          <Orders />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute verifyStrict exact path={ROUTE_NAMES.CREATE_ORDER}>
        <MainLayout>
          <CreateOrder />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute verifyStrict exact path={ROUTE_NAMES.COMPLETE}>
        <MainLayout>
          <CompleteOrder />
        </MainLayout>
      </PrivateRoute>
      <Route exact path={ROUTE_NAMES.TERMS_AND_CONDITIONS}>
        <TermsAndConditions />
      </Route>
      <Route path={ROUTE_NAMES.NOT_FOUND}>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Typography variant="h1">UGG Australia</Typography>
            <Typography gutterBottom variant="subtitle1">
              Page not found.
            </Typography>
            <Button
              color="primary"
              size="large"
              variant="contained"
              component={Link}
              to={ROUTE_NAMES.CREATE_ORDER}
            >
              Go Home
            </Button>
          </div>
        </div>
      </Route>
      <Route path="*">
        <Redirect to={ROUTE_NAMES.NOT_FOUND} />
      </Route>
    </Switch>
  );
};
