import React, { useState, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  IconButton,
  Hidden,
  Collapse,
  LinearProgress,
  Slide,
  useScrollTrigger,
} from "@material-ui/core";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import CustomIcon from "./CustomIcon";
import { useFirebaseContext } from "../contexts/Firebase";
import { useAlertContext } from "../contexts/Alert";
import ROUTE_NAMES from "../config/routes";
import LogoImg from "../assets/images/logo.png";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <>
      <Hidden smDown>{children}</Hidden>
      <Hidden mdUp>
        <Slide appear={false} direction="down" in={!trigger}>
          {children}
        </Slide>
      </Hidden>
    </>
  );
}

const NavBar = ({ navChildren, loading: layoutLoading, className }) => {
  const classes = useStyles();
  const history = useHistory();
  const firebase = useFirebaseContext();
  const [, alertDispatch] = useAlertContext();
  const [loading, setLoading] = useState(layoutLoading);
  const [open, setOpen] = useState(false);
  const { path } = useRouteMatch();

  const handleSignOut = useCallback(async () => {
    if (loading) return;
    alertDispatch({ type: "close" });
    setLoading(true);
    try {
      await firebase.doSignOut();
    } catch (error) {
      console.log("Sign out error: ", error);
    }
    history.replace(ROUTE_NAMES.SIGN_IN);
  }, [loading, firebase, history, alertDispatch]);

  const handleToggleSearch = useCallback(() => {
    setOpen((o) => !o);
  }, []);

  const handleOnOrders = useCallback(() => {
    history.push(ROUTE_NAMES.ORDERS);
  }, [history]);

  return (
    <HideOnScroll>
      <AppBar className={clsx([classes.appBar, className])} elevation={0}>
        <Toolbar
          disableGutters
          className={clsx([classes.bordered, classes.toolbar])}
        >
          <div className={classes.grow}>
            <Typography
              color="primary"
              component={Link}
              to={ROUTE_NAMES.CREATE_ORDER}
              variant="body1"
              className={clsx([
                classes.link,
                classes.createOrderButton,
                { [classes.active]: path === ROUTE_NAMES.CREATE_ORDER },
              ])}
            >
              <div className={classes.iconContainer}>
                <CustomIcon className={classes.cartIcon} name="ShoppingCart" />
                <CustomIcon
                  strokeWidth={3}
                  className={classes.addIcon}
                  name="Plus"
                />
              </div>
              <Hidden smDown>
                <span className={classes.text}>Create order</span>
              </Hidden>
            </Typography>
          </div>
          <Typography
            className={classes.link}
            variant="body1"
            onClick={handleSignOut}
          >
            <CustomIcon className={classes.icon} name="LogOut" />
            Logout
          </Typography>
          <div className={classes.divider}></div>
          <Typography
            className={clsx([
              classes.link,
              { [classes.active]: path === ROUTE_NAMES.PROFILE },
            ])}
            component={Link}
            to={ROUTE_NAMES.PROFILE}
            variant="body1"
          >
            <CustomIcon className={classes.icon} name="User" />
            <span>Profile</span>
          </Typography>
        </Toolbar>
        <Toolbar
          disableGutters
          className={clsx([classes.bordered, classes.toolbar])}
        >
          <Link className={classes.logo} to={ROUTE_NAMES.CREATE_ORDER}>
            <img height={60} width={60} src={LogoImg} alt="Ugg Australia" />
          </Link>
          {navChildren && typeof navChildren === "string" ? (
            <Typography color="textPrimary" variant="h1">
              {navChildren}
            </Typography>
          ) : (
            <Hidden smDown>{navChildren}</Hidden>
          )}
          <div className={classes.grow}></div>
          {typeof navChildren !== "string" && (
            <Hidden mdUp>
              <div className={classes.button}>
                <IconButton
                  aria-label="search action"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleToggleSearch}
                >
                  <CustomIcon name="Search" />
                </IconButton>
              </div>
            </Hidden>
          )}
          <div
            className={clsx([
              classes.button,
              { [classes.active]: path === ROUTE_NAMES.ORDERS },
            ])}
          >
            <div className={classes.customIconButton} onClick={handleOnOrders}>
              <CustomIcon name="FileText" />
              <Typography color="inherit" component="p" variant="caption">
                My orders
              </Typography>
            </div>
          </div>
        </Toolbar>
        {typeof navChildren !== "string" && (
          <Hidden mdUp>
            <Collapse in={open}>
              <Toolbar className={classes.bordered}>{navChildren}</Toolbar>
            </Collapse>
          </Hidden>
        )}
        {(loading || layoutLoading) && (
          <LinearProgress aria-describedby="navbar-loader" />
        )}
      </AppBar>
    </HideOnScroll>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    alignItems: "stretch",
    "& > *": {
      alignItems: "center",
      display: "flex",
    },
  },
  bordered: {
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 1,
  },
  link: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    minHeight: "inherit",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.text.secondary,
    },
    "&:first-child": {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  },
  grow: {
    flexGrow: 1,
  },
  divider: {
    borderRightWidth: 1,
    borderRightColor: theme.palette.divider,
    borderRightStyle: "solid",
    minHeight: "inherit",
    width: 1,
  },
  logo: {
    cursor: "pointer",
    margin: theme.spacing(1, 3),
  },
  button: {
    "&:last-child": {
      minWidth: 80,
    },
    "&:last-child > div": {
      paddingBottom: 0,
      textAlign: "center",
      margin: "0 auto",
    },
    "&:last-child > div > button": {
      paddingBottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 1),
    },
  },
  active: {
    borderBottomColor: theme.palette.primary.main,
    borderBottomStyle: "solid",
    borderBottomWidth: 3,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  iconContainer: {
    display: "inline-block",
    position: "relative",
    marginRight: theme.spacing(2),
    verticalAlign: "middle",
  },
  cartIcon: {
    height: 20,
    width: 20,
    verticalAlign: "middle",
  },
  addIcon: {
    height: 15,
    width: 15,
    position: "absolute",
    top: -8,
    right: -13,
    zIndex: 2,
  },
  createOrderButton: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  customIconButton: {
    color: theme.palette.text.primary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.text.secondary,
    },
  },
}));

NavBar.propTypes = {
  navChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default NavBar;
