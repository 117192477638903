import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
  makeStyles,
  Paper,
  Collapse,
  Hidden,
} from "@material-ui/core";
import currencyFormatter from "../utils/currency";
import ColorSquare from "./ColorSquare";
import clsx from "clsx";
import CustomIcon from "./CustomIcon";
import PropTypes from "prop-types";

const OrderItem = ({
  colors = [],
  sizes = [],
  name,
  id,
  quantity,
  total,
  list = [],
  onRemoveItem,
  onRemoveItemBySortKey,
  loading,
  edit,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [quant, setQuant] = useState(quantity);

  const seeMoreMemo = useMemo(
    () => (
      <div className={classes.action} onClick={() => setOpen((v) => !v)}>
        <Typography variant="caption">See {open ? "less" : "more"}</Typography>
        <CustomIcon
          fontSize="small"
          name={open ? "ChevronUp" : "ChevronDown"}
        />
      </div>
    ),
    [classes, open]
  );

  const getColorProp = useCallback(
    (id, prop) => {
      const selected = colors.find((c) => c.id === id);
      if (!selected) return null;
      return selected[prop];
    },
    [colors]
  );

  const getSizeProp = useCallback(
    (id, prop) => {
      const selected = sizes.find((s) => s.id === id);
      if (!selected) return null;
      return selected[prop];
    },
    [sizes]
  );

  const handleRemoveItem = useCallback(() => {
    if (onRemoveItem) onRemoveItem(id);
  }, [id, onRemoveItem]);

  const handleRemoveItemBySortKey = useCallback(
    (sortKey) => {
      if (onRemoveItemBySortKey) onRemoveItemBySortKey(sortKey);
    },
    [onRemoveItemBySortKey]
  );

  useEffect(() => {
    if (quant !== quantity) {
      setQuant(quantity);
      setOpen(true);
    }
  }, [quant, quantity]);

  const listMemo = useMemo(
    () => (
      <Paper variant="outlined" className={classes.list}>
        <Grid className={classes.listHeaders} container>
          <Grid item xs={5} sm={5} md={6}>
            <Typography variant="body2">Colour</Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={3}>
            <Typography variant="body2">Size</Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <Typography variant="body2">Qty</Typography>
          </Grid>
        </Grid>
        {list.map((i) => (
          <Grid
            container
            key={`${name}_${i.sortKey}`}
            alignItems="center"
            justify="flex-start"
          >
            <Grid
              item
              xs={5}
              sm={5}
              md={5}
              className={classes.colorLabelContainer}
            >
              <Typography
                className={clsx([classes.capitalize, classes.colorLabelText])}
                variant="body1"
              >
                {getColorProp(i.color, "name")}
              </Typography>
              <ColorSquare
                color={getColorProp(i.color, "hex")}
                className={classes.colorSquare}
              />
            </Grid>
            <Hidden smDown>
              <Grid item md={1}></Grid>
            </Hidden>
            <Grid item xs={4} sm={4} md={3}>
              <Typography className={classes.capitalize} variant="body1">
                {getSizeProp(i.size, "name")}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="body1">{i.quantity}</Typography>
            </Grid>
            {edit && (
              <Grid item xs={1} sm={1} md={1}>
                <IconButton
                  aria-label="backspace-variation"
                  aria-controls="variation-backspace"
                  aria-haspopup="true"
                  color="secondary"
                  size="small"
                  onClick={handleRemoveItemBySortKey.bind(null, i.sortKey)}
                  disabled={loading}
                >
                  <CustomIcon name="Delete" fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
      </Paper>
    ),
    [
      classes,
      name,
      list,
      getColorProp,
      getSizeProp,
      handleRemoveItemBySortKey,
      loading,
      edit,
    ]
  );

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item xs={6} sm={6} md={6}>
          <Typography
            className={clsx([classes.capitalize, classes.name])}
            variant="body2"
          >
            {name}
          </Typography>
          {seeMoreMemo}
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          <Typography variant="body1">{quant}</Typography>
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <Typography variant="body1">
            {currencyFormatter.format(total)}
          </Typography>
        </Grid>
        {edit && (
          <Grid item xs={1} sm={1} md={1}>
            <IconButton
              aria-label="backspace"
              aria-controls="order-backspace"
              aria-haspopup="true"
              color="secondary"
              onClick={handleRemoveItem}
              disabled={loading}
            >
              <CustomIcon name="Delete" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Collapse in={open}>{listMemo}</Collapse>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.divider,
    borderWidth: 1,
    padding: theme.spacing(1, 4),
  },
  capitalize: {
    textTransform: "capitalize",
  },
  action: {
    cursor: "pointer",
    width: "fit-content",
    "& > span": {
      verticalAlign: "super",
    },
  },
  list: {
    borderRadius: 15,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
  },
  listHeaders: {
    marginBottom: theme.spacing(1),
  },
  colorLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(4),
    },
  },
  colorLabelText: {
    flex: 1,
  },
  colorSquare: {
    verticalAlign: "middle",
  },
  name: {
    paddingRight: theme.spacing(2),
  },
}));

OrderItem.propTypes = {
  colors: PropTypes.array,
  sizes: PropTypes.array,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  total: PropTypes.number,
  list: PropTypes.array,
  onRemoveItem: PropTypes.func,
  onRemoveItemBySortKey: PropTypes.func,
  loading: PropTypes.bool,
  edit: PropTypes.bool,
};

export default OrderItem;
