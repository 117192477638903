import {
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ROUTE_NAMES from "../config/routes";
import { useFirebaseContext } from "../contexts/Firebase";
import { ReactComponent as EmailFailIcon } from "../assets/icons/email-fail.svg";
import { ReactComponent as EmailSuccessIcon } from "../assets/icons/email-success.svg";
import CustomCard from "../components/CustomCard";

const ValidateAccount = () => {
  const classes = useStyles();
  const location = useLocation();
  const firebase = useFirebaseContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { search } = location;
      if (!search) return {};
      const params = new URLSearchParams(search);
      const token = params.get("t");
      if (!token) {
        setError(true);
        setLoading(false);
        return;
      }
      try {
        await firebase.validateAccount(token);
        setSuccess(true);
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div className={classes.root}>
        <CircularProgress color="primary" />
      </div>
    );

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        {success && (
          <CustomCard
            title="Email verified"
            color="info"
            icon={<EmailSuccessIcon />}
          >
            <>
              <Typography gutterBottom variant="body1">
                Your account has been confirmed successfully. Now you can start
                creating new orders.
              </Typography>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                component={Link}
                to={ROUTE_NAMES.CREATE_ORDER}
                replace
              >
                Continue
              </Button>
            </>
          </CustomCard>
        )}
        {error && (
          <CustomCard
            title="Email not verified"
            color="error"
            icon={<EmailFailIcon />}
          >
            <>
              <Typography gutterBottom variant="body1">
                We were unable to confirm your account email address. Please
                resend the confirmation email and try again.
              </Typography>
              <Button
                className={classes.button}
                color="secondary"
                variant="contained"
                component={Link}
                to={ROUTE_NAMES.PROFILE}
                replace
              >
                Return to profile
              </Button>
            </>
          </CustomCard>
        )}
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  paper: {
    borderColor: theme.palette.divider,
    borderRadius: 28,
    borderStyle: "solid",
    borderWidth: 1,
    maxWidth: 345,
    overflow: "hidden",
  },
  header: {
    padding: theme.spacing(3),
    textAlign: "center",
    position: "relative",
    height: 120,
    "&::after": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: "50%",
      borderTopRightRadius: "50%",
      height: 30,
      width: "100%",
      zIndex: 2,
    },
  },
  icon: {
    position: "absolute",
    top: "calc(50% - 30px)",
    left: "calc(50% - 35px)",
    zIndex: 10,
  },
  failBg: {
    backgroundColor: "#FFF0F0",
  },
  successBg: {
    backgroundColor: "#EBF2FC",
  },
  content: {
    padding: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export default ValidateAccount;
