import React, { useCallback, useEffect } from "react";
import { makeStyles, TextField, Grid } from "@material-ui/core";
import * as yup from "yup";
import useForm from "../hooks/form.hook";
import PropTypes from "prop-types";
import AutocompleteAddress from "./AutocompleteAddress";

const COMMENTS_LENGTH_LIMIT = 800;
const DEFAULT_STR_LENGTH = 255;
const PHONE_NUMBER = /^[0-9 ()+]+$/g;

const storeValidationSchema = yup.object({
  name: yup
    .string()
    .min(2, "Company name must be at least 2 characters")
    .max(DEFAULT_STR_LENGTH, "Max length exceeded")
    .required(),
  email: yup
    .string()
    .email("Must be a valid email address")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(PHONE_NUMBER, "Must be a valid phone number")
    .required("Phone number is required"),
  address: yup
    .string()
    .min(2, "Address must be at least 2 characters")
    .max(DEFAULT_STR_LENGTH, "Max length exceeded")
    .required("Address is required"),
  zip: yup.string().required("ZIP Code is required"),
  city: yup.string().required("City is required"),
  comments: yup
    .string()
    .max(COMMENTS_LENGTH_LIMIT, `Max ${COMMENTS_LENGTH_LIMIT} characters`)
    .optional(),
  state: yup.string().optional(),
  postcodeID: yup.string().optional(),
});

const StoreForm = ({
  children,
  handleUpdateData,
  initialValues = {},
  locations = [],
  onSearchLocation,
  loadingLocations = false,
}) => {
  const classes = useStyles();
  const { useInput, values, errors, isValid, setValues } = useForm(
    {
      address: initialValues.address || "",
      city: initialValues.city || "",
      email: initialValues.email || "",
      name: initialValues.name || "",
      phone: initialValues.phone || "",
      zip: initialValues.zip || "",
      state: initialValues.state || "",
      postcodeID: initialValues.postcodeID || null,
    },
    storeValidationSchema
  );

  const handleLocationSelected = useCallback(
    (val) => {
      setValues((v) => ({ ...v, ...val }));
    },
    [setValues]
  );

  // Exec parent callback when data is updated
  useEffect(() => {
    handleUpdateData && handleUpdateData({ errors, isValid, values });
  }, [handleUpdateData, errors, isValid, values]);

  return (
    <form noValidate autoComplete="off" className={classes.root}>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12}>
          <TextField
            label="Store name"
            variant="outlined"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...useInput("name")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...useInput("email")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone number"
            variant="outlined"
            type="phone"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...useInput("phone")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address"
            variant="outlined"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...useInput("address")}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AutocompleteAddress
            locations={locations}
            loadingLocations={loadingLocations}
            onLocationSelected={handleLocationSelected}
            onSearchLocation={onSearchLocation}
            values={values}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12}>
          <TextField
            label="Additional comments"
            variant="outlined"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...useInput("comments")}
          />
        </Grid> */}
      </Grid>
      {children}
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  grid: {
    "& > *": {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
}));

StoreForm.propTypes = {
  children: PropTypes.element,
  handleUpdateData: PropTypes.func,
  initialValues: PropTypes.exact({
    address: PropTypes.string,
    city: PropTypes.string,
    comments: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    zip: PropTypes.string,
  }),
};

export default StoreForm;
