/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useState, useEffect } from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import useForm from "../hooks/form.hook";
import BaseButton from "../components/BaseButton";
import * as yup from "yup";
import ROUTE_NAMES from "../config/routes";
import { useFirebaseContext } from "../contexts/Firebase";
import logoImage from "../assets/images/logo.png";
import backgroundImage from "../assets/images/signup.jpg";

// Signup form schema validation
const signupSchema = yup.object({
  email: yup.string().required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must have 6 characters at least"),
  confirmPassword: yup.string().required("Confirm password is required"),
});

// Signup page
const Signup = () => {
  const classes = useStyles();
  const firebase = useFirebaseContext();
  const [loading, setLoading] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const [error, setError] = useState("");
  const { useInput, isValid, values } = useForm(
    { email: "", password: "", confirmPassword: "" },
    signupSchema
  );

  useEffect(() => {
    if (values.password !== values.confirmPassword) {
      if (values.password !== "" && values.confirmPassword !== "") {
        setMatchError(true);
      }
    } else {
      setMatchError(false);
    }
  }, [values.password, values.confirmPassword]);

  // Submit signup form
  const handleOnSubmit = useCallback(async () => {
    if (values.password !== values.confirmPassword) {
      return setMatchError(true);
    }
    if (isValid) {
      setLoading(true);
      try {
        await firebase.doCreateUserWithEmailAndPassword(
          values.email,
          values.password
        );
      } catch (error) {
        console.log("Error: ", error);
        const message = error.message || "Unexpected error occurred";
        setError(message);
        setLoading(false);
      }
    }
  }, [firebase, isValid, values]);

  // Add handle key listener to submit form on press enter key
  const handleKeyPress = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleOnSubmit();
      }
    },
    [handleOnSubmit]
  );

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <img src={`${logoImage}`} alt="Logo" className={classes.logoImg} />
          <Typography className={classes.title} color="primary" variant="h2">
            Register with us
          </Typography>
          <form className={classes.formContainer}>
            <TextField
              inputProps={{ autoComplete: "username" }}
              color="primary"
              type="email"
              placeholder="Your email"
              fullWidth
              {...useInput("email")}
            />
            <TextField
              inputProps={{ autoComplete: "new-password" }}
              color="primary"
              type="password"
              placeholder="Your password"
              fullWidth
              {...useInput("password")}
            />
            <TextField
              inputProps={{ autoComplete: "new-password" }}
              color="primary"
              type="password"
              placeholder="Repeat your password"
              fullWidth
              {...useInput("confirmPassword")}
              error={matchError}
              helperText={matchError && "Passwords must match"}
              onKeyDown={handleKeyPress}
            />
            <BaseButton
              type="button"
              color="primary"
              fullWidth
              size="large"
              variant="contained"
              onClick={handleOnSubmit}
              loading={loading}
              disabled={loading || !isValid || matchError}
            >
              Create account
            </BaseButton>
          </form>
          {error && (
            <Typography gutterBottom color="secondary" variant="caption">
              {error}
            </Typography>
          )}
          <Typography className={classes.divider} variant="body1">
            Already have an account?
          </Typography>
          <Button
            component={Link}
            to={ROUTE_NAMES.SIGN_IN}
            color="primary"
            fullWidth
            size="large"
            variant="outlined"
          >
            Sign in
          </Button>
          <Typography
            className={classes.terms}
            align="left"
            component="p"
            variant="caption"
          >
            By creating an account, you agree to the{" "}
            <Link
              className={classes.link}
              to={ROUTE_NAMES.TERMS_AND_CONDITIONS}
            >
              Terms of Service
            </Link>
            . For more information about UGG Australia privacy practices, see
            the UGG Australia Privacy Statement. We'll occasionally send you
            account-related emails.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  card: {
    borderRadius: 10,
    textAlign: "center",
    padding: theme.spacing(0, 1),
    width: "90%",
    [theme.breakpoints.up("md")]: {
      maxWidth: 500,
      padding: theme.spacing(3),
    },
  },
  title: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  formContainer: {
    "& > *": {
      display: "block",
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    "& > *:last-child": {
      marginBottom: 0,
    },
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  logoImg: {
    width: "90px",
    height: "90px",
  },
  terms: {
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
  }
}));

export default Signup;
