import { useState, useEffect, useCallback, useRef } from "react";
import isEmpty from "lodash/isEmpty";

const DEFAULT_ORDER_STATUS = "editing";

const useOrder = (firebase, id) => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const mounted = useRef(true);

  const fetchOrder = useCallback(async () => {
    setLoading(true);
    try {
      if (id) {
        const ref = await firebase.order(id).get();
        if (mounted.current) {
          if (ref.exists) {
            const ord = ref.data();
            ord.parsedItems = await firebase.parseOrderItemList(ord.items);
            setOrder(ord);
          } else {
            setOrder(null);
          }
          setLoading(false);
          setLoaded(true);
        }
      } else {
        const { docs, empty } = await firebase
          .orders()
          .where("user", "==", firebase.currentUser())
          .where("status", "==", "editing")
          .get();
        if (mounted.current) {
          if (!empty) {
            const ord = docs[0].data();
            ord.parsedItems = await firebase.parseOrderItemList(ord.items);
            setOrder(ord);
          } else {
            setOrder(null);
          }
          setLoading(false);
          setLoaded(true);
        }
      }
    } catch (error) {
      console.log("Error fetching order: ", error);
      setError(error);
      setLoading(false);
    }
  }, [firebase, id]);

  const addItems = useCallback(
    async (items = []) => {
      setLoading(true);
      // Get firestore timestamps
      const { createdAt, updatedAt } = firebase.getFirebaseTimestamps();
      const userRef = await firebase.currentUser();
      if (order) {
        const currentItemsSortKeys = order.items.map((i) => i.sortKey);
        const newItems = items.filter(
          (i) => !currentItemsSortKeys.includes(i.sortKey)
        );
        const oldItems = items.filter((i) =>
          currentItemsSortKeys.includes(i.sortKey)
        );
        let updateItems = order.items;
        if (!isEmpty(oldItems)) {
          oldItems.forEach((i) => {
            updateItems = updateItems.map((ui) => {
              if (ui.sortKey === i.sortKey) {
                ui.quantity += i.quantity;
              }
              return ui;
            });
          });
        }
        if (!isEmpty(newItems)) {
          updateItems = [...updateItems, ...newItems];
        }
        await firebase.order(order.id).update({
          items: updateItems,
        });
        await firebase.order(order.id).update({
          updatedAt,
          ...(await firebase.calculateOrderPriceValues(order.id)),
        });
      } else {
        const data = {
          createdAt,
          updatedAt,
          status: DEFAULT_ORDER_STATUS,
          user: userRef,
          items,
        };
        const orderRef = firebase.orders().doc();
        await orderRef.set({
          ...data,
          id: orderRef.id,
        });
        await firebase.order(orderRef.id).update({
          ...(await firebase.calculateOrderPriceValues(orderRef.id)),
        });
      }
      await fetchOrder();
    },
    [firebase, order, fetchOrder]
  );

  const removeItem = useCallback(
    async (itemID) => {
      setLoading(true);
      const { id, items } = order;
      const newItems = items.filter((i) => i.id.id !== itemID);
      if (newItems.length === 0) {
        await firebase.order(id).delete();
      } else {
        const { updatedAt } = firebase.getFirebaseTimestamps();
        await firebase.order(id).update({
          items: newItems,
        });
        await firebase.order(id).update({
          updatedAt,
          ...(await firebase.calculateOrderPriceValues(id)),
        });
      }
      await fetchOrder();
    },
    [order, firebase, fetchOrder]
  );

  const removeItemBySortKey = useCallback(
    async (sortKey) => {
      const { id, items } = order;
      const newItems = items.filter((i) => i.sortKey !== sortKey);
      if (newItems.length === 0) {
        await firebase.order(id).delete();
      } else {
        const { updatedAt } = firebase.getFirebaseTimestamps();
        await firebase.order(id).update({
          items: newItems,
          ...(await firebase.calculateOrderPriceValues(id)),
        });
        await firebase.order(id).update({
          updatedAt,
          ...(await firebase.calculateOrderPriceValues(id)),
        });
      }
      await fetchOrder();
    },
    [order, firebase, fetchOrder]
  );

  const updateOrder = useCallback(
    async (data) => {
      setLoading(true);
      const { updatedAt } = firebase.getFirebaseTimestamps();
      await firebase.order(order.id).update(data);
      await firebase.order(order.id).update({
        updatedAt,
        ...(await firebase.calculateOrderPriceValues(order.id)),
      });
      await fetchOrder();
      setLoading(false);
    },
    [firebase, order, fetchOrder]
  );

  const finishOrder = useCallback(
    async ({
      payment,
      shipping,
      purchaseOrder,
      comments,
    }) => {
      setLoading(true);
      await firebase.confirmOrder(order.id, {
        shipping,
        payment,
        purchaseOrder,
        comments,
      });
      await fetchOrder();
      setLoading(false);
    },
    [firebase, order, fetchOrder]
  );

  useEffect(() => {
    fetchOrder();
    return () => {
      mounted.current = false;
    };
  }, [fetchOrder, mounted]);

  return {
    order,
    error,
    loaded,
    loading,
    addItems,
    removeItem,
    removeItemBySortKey,
    updateOrder,
    finishOrder,
  };
};

export default useOrder;
