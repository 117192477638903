import React, { useState, useCallback } from "react";
import { makeStyles, Typography, Fab } from "@material-ui/core";
import CustomIcon from "./CustomIcon";
import MasterCardImage from "../assets/images/master-card.png";
import VisaImage from "../assets/images/visa.png";
import AmericanExpressImage from "../assets/images/american-express.png";
import clsx from "clsx";
import AlertDialog from "./AlertDialog";
import PropTypes from "prop-types";

const getCardImage = (type) => {
  switch (type) {
    case "visa":
      return VisaImage;
    case "mastercard":
      return MasterCardImage;
    case "amex":
      return AmericanExpressImage;
    default:
      return null;
  }
};

const CreditCard = ({
  exp,
  number,
  type,
  classes: parentClasses = { root: null },
  showDeleteButton = false,
  onDelete,
  onClick,
}) => {
  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);

  const handleOpenAlert = useCallback(() => {
    setOpenAlert(true);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setOpenAlert(false);
  }, []);

  return (
    <>
      <AlertDialog
        open={openAlert}
        handleClose={handleCloseAlert}
        message="Are you sure to delete this credit card"
        handleConfirm={onDelete}
      />
      <div
        onClick={onClick}
        className={clsx([classes.card, parentClasses.root])}
      >
        <img src={getCardImage(type)} alt="Credit card" />
        <Typography className={classes.exp} variant="body1">
          {exp}
        </Typography>
        <div className={classes.number}>
          <Typography className={classes.numberLabel} variant="body1">
            Card number
          </Typography>
          <Typography className={classes.numberValue} variant="body1">
            <CustomIcon
              name="Circle"
              fontSize="small"
              htmlColor="#BFBFBF"
              fill="#BFBFBF"
            />
            <span>{number.substr(number.length - 4)}</span>
          </Typography>
        </div>
        {showDeleteButton && (
          <Fab
            size="small"
            className={classes.delete}
            onClick={handleOpenAlert}
          >
            <CustomIcon fontSize="small" htmlColor="#FFF" name="Trash" />
          </Fab>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 10,
    position: "relative",
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      width: 243,
      height: 146,
    },
    "& > img": {
      verticalAlign: "middle",
      height: "auto",
      width: "100%",
    },
  },
  exp: {
    position: "absolute",
    top: 25,
    right: 15,
    color: theme.palette.common.white,
  },
  numberLabel: {
    color: theme.palette.common.white,
  },
  numberValue: {
    color: theme.palette.common.white,
    "& > span": {
      marginLeft: theme.spacing(1),
      verticalAlign: "middle",
    },
    "& > svg": {
      verticalAlign: "middle",
    },
  },
  number: {
    position: "absolute",
    bottom: 25,
    left: 20,
    color: theme.palette.common.white,
  },
  mastercard: {
    backgroundImage: `url(${MasterCardImage})`,
  },
  visa: {
    backgroundImage: `url(${VisaImage})`,
  },
  american: {
    backgroundImage: `url(${AmericanExpressImage})`,
  },
  delete: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    position: "absolute",
    bottom: 15,
    right: 15,
  },
}));

CreditCard.propTypes = {
  exp: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

export default CreditCard;
