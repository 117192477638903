import React, { useState, useCallback } from "react";
import NavBar from "../components/NavBar";
import Sidebar from "../components/Sidebar";
import { makeStyles, Toolbar } from "@material-ui/core";
import {
  Route,
  useRouteMatch,
  Redirect,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import ROUTE_NAMES from "../config/routes";
import Profile from "../pages/Profile";
import Stores from "../pages/Stores";
import PaymentMethods from "../pages/PaymentMethods";
import Settings from "../pages/Settings";
import PrivateRoute from "../routes/PrivateRoute";
import UpdatePassword from "../pages/UpdatePassword";

const customToolbarHeight = 76;
const tabsHeight = 56;

const setNavbarChildren = (path) => {
  switch (path) {
    case ROUTE_NAMES.INFORMATION:
      return "Profile";
    case ROUTE_NAMES.STORES:
      return "Stores";
    case ROUTE_NAMES.PAYMENT:
      return "Payment methods";
    case ROUTE_NAMES.SETTINGS:
      return "Settings";
    case ROUTE_NAMES.NOTIFICATIONS:
      return "Notifications";
    default:
      return "";
  }
};

const MenuLayout = (pro) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(null);
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const handleSetLayoutLoader = useCallback((load) => {
    setLoading(load);
  }, []);

  return (
    <>
      <NavBar
        className={classes.appBar}
        navChildren={setNavbarChildren(location.pathname)}
        loading={loading}
      />
      <div className={classes.root}>
        <Sidebar pathname={location.pathname} history={history} />
        <main className={classes.content}>
          <Toolbar />
          <Toolbar className={classes.extraToolbar} />
          <Switch>
            <Route exact path={path}>
              <Redirect to={{ pathname: ROUTE_NAMES.INFORMATION, state: location.state }} />
            </Route>
            <PrivateRoute exact path={ROUTE_NAMES.INFORMATION}>
              <Profile handleSetLayoutLoader={handleSetLayoutLoader} />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTE_NAMES.UPDATE_PASSWORD}>
              <UpdatePassword />
            </PrivateRoute>
            <PrivateRoute verifyStrict path={ROUTE_NAMES.STORES}>
              <Stores handleSetLayoutLoader={handleSetLayoutLoader} />
            </PrivateRoute>
            <PrivateRoute verifyStrict path={ROUTE_NAMES.PAYMENT}>
              <PaymentMethods handleSetLayoutLoader={handleSetLayoutLoader} />
            </PrivateRoute>
            <Route path={ROUTE_NAMES.SETTINGS}>
              <Settings handleSetLayoutLoader={handleSetLayoutLoader} />
            </Route>
            {/* <PrivateRoute path={ROUTE_NAMES.NOTIFICATIONS}>
              <Notifications handleSetLayoutLoader={handleSetLayoutLoader} />
            </PrivateRoute> */}
          </Switch>
        </main>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "stretch",
    display: "flex",
    minHeight: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    marginBottom: tabsHeight,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      display: "block",
      justifyContent: "center",
      maxHeight: "unset",
    },
  },
  extraToolbar: {
    minHeight: customToolbarHeight,
  },
}));

export default MenuLayout;
